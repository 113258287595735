<template>
  <div>
    <h2>Mein Ausbildungsstatus
      <span class="is-pulled-right">
            <b-tag class="ampel" style="opacity:0.6;" v-if="educationStatus.ampel === 0">Keine Funktion
            </b-tag>
            <b-tag class="ampel" type="is-success" v-if="educationStatus.ampel === 3">Vollstädig
            </b-tag>
            <b-tag class="ampel" type="is-warning" v-if="educationStatus.ampel === 2">Ausstehend
            </b-tag>
            <b-tag class="ampel" type="is-danger" v-if="educationStatus.ampel === 1">Unvollständig
            </b-tag>
                </span>

    </h2>
    <b-table :data="educationStatus.requirements"
             :row-class="(row) => !row.isRequired && 'optional'"
             bordered
             striped
             :mobile-cards="false"

    >
      <b-table-column v-slot="props" label="Voraussetzung">
                    <span :class="{'optional' : !props.row.isRequired}">
                        {{ props.row.template.name }} <span v-if="!props.row.isRequired">(optional)</span>
                    </span>
      </b-table-column>
      <b-table-column v-slot="props" label="Status" numeric cell-class="nowrap">
        <router-link :to="{name: 'Seminars', query: {query: props.row.template.name}}"
                     v-if="props.row.state === 1">Termine finden
        </router-link>&nbsp;

        <b-icon class="statico" icon="check" type="is-success" v-if="props.row.state === 3"/>
        <b-icon class="statico" icon="close" type="is-danger" v-if="props.row.state === 1"/>
        <b-icon class="statico" icon="timer-sand" type="is-warning" v-if="props.row.state === 2"/>
      </b-table-column>
      <template v-slot:empty>
        <div v-if=" !$apollo.queries.EaMyFunctions.loading">
          <div class="has-text-centered empty">
            Sie haben aktuell keine Funktionen <br>für die Sie Seminare benötigen.
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import utils from '../utils'
import GET_MY_EDUCATION_QUERY from '../graphql/EaMyEducation.gql'

export default {
  name: "MyEducationStatus",
  data() {
    return {
      EaMyFunctions: [],
      EaMyCertificates: [],
      EaMyParticipations: []
    }
  },
  computed: {
    educationStatus() {
      return utils.member.memberEducationStatus({
        functions: this.EaMyFunctions,
        certificates: this.EaMyCertificates,
        participations: this.EaMyParticipations
      })
    }
  },
  apollo: {
    EaMyFunctions: {query: GET_MY_EDUCATION_QUERY},
    EaMyCertificates: {query: GET_MY_EDUCATION_QUERY},
    EaMyParticipations: {query: GET_MY_EDUCATION_QUERY}
  }

}
</script>

<style scoped>
.ampel {
  vertical-align: text-bottom;
}

.optional {
  opacity: 0.6;
}

.statico {
  vertical-align: sub;
}

.empty {
  padding: 1em;
}

</style>