<template>
  <div>
    <auth-base>
      <h1>Kontakt</h1>
      <b-message v-if="failure" type="is-danger">
        <strong>Ein Fehler ist aufgetreten</strong><br/>

      </b-message>
      <b-message v-if="success" type="is-success">
        <strong>Nachricht verschickt</strong><br/>
        Ihre Nachricht wurde erfolgreich verschickt.<br/>
        Wir melden uns zeitnah bei Ihnen.
      </b-message>
      <template v-if="!success">
        <b-field :type="errors.fullname ? 'is-danger': ''" label="Ihr Name">

          <b-input v-if="!$store.getters.loggedIn" v-model="fullname" placeholder="Ihr Name"></b-input>
          <p v-else>{{ $store.state.auth.user.fullName }}</p>
        </b-field>
        <b-field :label="'Ihre '+$tc('Mitgliedernummer')+($store.getters.loggedIn? '':' (falls vorhanden)')">

          <b-input v-if="!$store.getters.loggedIn" v-model="mitgliedernummer"
                   :placeholder="'Ihre '+$tc('Mitgliedernummer')"></b-input>
          <p v-else>{{ $store.state.auth.user.mitgliedernummer }}</p>
        </b-field>
        <b-field :type="errors.email ? 'is-danger': ''" label="Ihre E-Mail Adresse">

          <b-input v-if="!$store.getters.loggedIn" v-model="email" placeholder="Ihre E-Mail Adresse"></b-input>
          <p v-else>{{ $store.state.auth.user.email }}</p>
        </b-field>
        <b-field :type="errors.supportMessage ? 'is-danger': ''" label="Nachricht">
                    <textarea v-model="supportMessage" class="textarea"
                              placeholder="Geben Sie hier Ihre Nachricht an uns ein"
                              style="min-height:300px;"></textarea>
        </b-field>
        <button :class="{'is-loading': isLoading}" class="button is-primary m-t-md" @click="sendMessage">
          Absenden
        </button>
      </template>
    </auth-base>
  </div>
</template>

<script>
import AuthBase from "./auth/AuthBase";
import SUPPORT_MUTATION from "../graphql/EaContact.gql"
import {hasServerError} from "@/mixins";

export default {
  name: "Support",
  components: {AuthBase},
  props: ['message'],
  mixins: [hasServerError],
  data() {
    return {
      supportMessage: this.message,
      mitgliedernummer: '',
      fullname: null,
      email: null,
      success: false,
      failure: false,
      isLoading: false,
      errors: {}
    }
  },
  methods: {
    validate() {
      this.errors = {}
      if (!this.$store.getters.loggedIn) {
        this.errors.email = !this.email
        this.errors.fullname = !this.fullname
      }
      this.errors.supportMessage = !this.supportMessage

      for (let i in this.errors) {
        if (this.errors[i] === true) {
          return false
        }
      }
      return true
    },
    sendMessage() {
      if (!this.validate()) return
      this.isLoading = true

      this.$apollo.mutate({
        mutation: SUPPORT_MUTATION,
        variables: {
          data: {
            mitgliedernummer: this.mitgliedernummer,
            email: this.email,
            message: this.supportMessage,
            name: this.fullname
          }
        }
      }).then(() => {
        this.failure = false
        this.isLoading = false
        this.success = true
      }).catch(error => {
        this.isLoading = false
        this.failure = true
        this.serverError = error
      })
    }
  }
}
</script>

<style scoped>

</style>