import '@babel/polyfill';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import apolloClient from './apollo'
import VueApollo from 'vue-apollo'
import VueMoment from 'vue-moment'
import moment from 'moment'
import Buefy from 'buefy'
import {marked} from 'marked'
import '@mdi/font/css/materialdesignicons.css'
import {API_INIT_CSRF, loadSettings} from "./config";
import {DEFAULT_LANG, FALLBACK_LANG} from "./config";
import Translator from "i18n"

Vue.prototype.$tc = Translator(DEFAULT_LANG, FALLBACK_LANG);

Vue.use(VueApollo)
Vue.use(Buefy)

Vue.config.productionTip = false
moment.locale('de')
Vue.use(VueMoment, {
  moment
})

marked.setOptions({
  sanitize: true,
  breaks: true
})
Vue.filter('marked', function (input) {
  return marked(input)
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'isLoading'
  },
})

fetch(API_INIT_CSRF, {credentials: 'include', mode: 'cors'}).then(async () => {
  await loadSettings()
  await store.dispatch('getUser')
  new Vue({
    router,
    store,
    apolloProvider: apolloProvider,
    render: h => h(App)
  }).$mount('#app')
})