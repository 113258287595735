<template>
  <auth-base>
    <div class="">
      <b-message v-if="!!message" type="is-info">
        <div v-html="message"/>
      </b-message>
      <h1>Einloggen</h1>
      <b-message v-if="error" has-icon type="is-danger">
        <b>Oh, nein!</b>
        <br/>
        <template v-if="error.message === 'Login Error'">
          Es wurde kein Account mit dieser Kombination aus E-Mail-Adresse und Passwort gefunden.
          <div class="mt-2" style="line-height: 2;">
            <router-link :to="{name: 'SendPasswordReset', params: {email: this.email}}" class="is-block">Passwort
              vergessen?
            </router-link>
            <router-link v-if="$config.SHOW_REGISTER" :to="{name: 'Register', params: {email: this.email}}"
                         class="is-block">
              Zur Registrierung
            </router-link>
          </div>
        </template>
        <template v-else-if="error.message === 'Too many login attempts'">
          Sie haben zu oft versucht sich mit der angebenen E-Mail-Adresse einzuloggen.
          Bitte versuchen Sie es in einer <b>halben Stunde</b> erneut.
        </template>
        <template v-else-if="error.message === 'Network Error'">
          Ein <b>Netzwerkfehler</b> ist aufgetreten.<br/>
          Sind Sie korrekt mit dem Internet verbunden?
        </template>
        <template v-else>
          {{ error.message }}
          Ein <b>Serverfehler</b> ist aufgetreten.<br/>Probiere es vielleicht später noch einmal.
        </template>
      </b-message>
      <form>
        <div class="field">
          <label class="label">E-Mail-Adresse</label>
          <div class="control">
            <input @keydown.enter="login"
                   autofocus=""
                   class="input is-medium"
                   placeholder="Ihre E-Mail Adresse"
                   type="email"
                   v-model="email">
          </div>
        </div>
        <div class="field">
          <label class="label">Passwort</label>
          <div class="control">
            <input @keydown.enter="login"
                   class="input is-medium"
                   placeholder="Ihr Passwort"
                   type="password"
                   v-model="password">
          </div>
        </div>

        <!--<div class="field">
            <label class="checkbox">
                <input v-model="rememberme" type="checkbox">
                Remember me
            </label>
        </div>-->
        <a :class="{'is-loading' : isLoading}" @click="login" class="button is-block is-primary is-medium">Einloggen</a>
      </form>
    </div>
    <div class="has-text-grey m-t-lg has-text-weight-normal">
      <p v-if="$config.SHOW_REGISTER" class="m-b-lg">Sie haben noch keinen Account?<br/>
        <router-link to="/register">Zur Registrierung</router-link>
      </p>
      <p>
        Sie haben Ihr Passwort vergessen?<br/>
        <router-link to="/password/email">Passwort zurücksetzen</router-link>
      </p>
      <p v-if="$config.SHOW_SUPPORT_FORM" class="m-t-lg">
        Sie haben Ihre E-Mail-Adresse vergessen?<br/>
        <router-link
            :to="{name:'Support', params:{message:'Hallo,\nIch weiß nicht mehr welche E-Mail-Adresse von mir beim VdK hinterlegt ist.'}}">
          Supportanfrage stellen
        </router-link>
      </p>
    </div>
  </auth-base>
</template>

<script>
import AuthBase from './AuthBase'

export default {
  components: {AuthBase},
  name: 'Login',
  props: ['message'],
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      error: null,
    }
  },
  methods: {
    login() {
      this.error = ''
      this.isLoading = true
      this.$store.dispatch('login', {
        'email': this.email,
        'password': this.password,
        redirectTo: this.$route.query.from
      }).then(() => {
        this.isLoading = false
      }).catch(error => {
        this.error = error
        this.isLoading = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
