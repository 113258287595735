<template>
  <div>
    <markdown-setting id="eaLecturerpage" class="box"></markdown-setting>

    <div class="columns">
      <template v-if="showCurrent">
        <div class="headline is-borderless column is-marginless">Aktuelle Seminare</div>
        <div class="column is-narrow"><a @click="showCurrent = false">zu vergangenen Seminare</a></div>
      </template>
      <template v-else>
        <h1 class="headline is-borderless column is-marginless">Vergangene Seminare</h1>
        <div class="column is-narrow"><a @click="showCurrent = true">zu aktuellen Seminare</a></div>
      </template>
    </div>
    <b-table :data="LectureshipSeminars"
             striped
             :loading="!!isLoading"
             bordered
             :paginated="!showCurrent"
             :per-page="15"
             :mobile-cards="false"
             :default-sort="['begin', 'asc']"
             sortable>
      <b-table-column v-slot="props" label="Datum" field="begin" sortable class="dw6">
        {{ props.row.begin | moment('DD.MM.YYYY') }}
      </b-table-column>

      <b-table-column v-slot="props" label="Name">
        <router-link :to="{name: 'Seminar', params: {id: props.row.id}}">
          {{ props.row.template.name }} ({{ props.row.number }})
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" label="Tagungsort" class="dw18">
        {{ props.row.placeName }}
      </b-table-column>
      <b-table-column v-slot="props" label="Status" class="dw8">
        <seminar-status :status="props.row.status"/>
      </b-table-column>
      <b-table-column v-slot="props" label="Anw." class="dw2" numeric :visible="showCurrent">
        <a :href="`${$config.API_EXPORT_URL}/seminar/${props.row.id}/attendance`" target="_blank">
          <b-icon icon="file-download"></b-icon>
        </a>
      </b-table-column>
      <div slot="empty" class="card-content has-text-centered" v-if="!isLoading">
        <template v-if="showCurrent">
          Sie haben aktuell keine Seminare ausstehend.
        </template>
        <template v-else>
          Sie haben noch keine vergangenen Seminare.
        </template>
        <br/>
      </div>
    </b-table>

    <h2 class="m-t-xl">Ihre Seminartypen</h2>
    <div style="position: relative; min-height:10rem;">
      <b-loading :active="!!isLoading" :is-full-page="false"></b-loading>
      <div class="columns is-multiline">
        <div class="column is-half-desktop"
             v-for="lectureship in EaMyLectureships.slice().sort((a,b) => a.template.name.localeCompare(b.template.name))"
             :key="lectureship.id">
          <div class="panel">
            <div class="panel-heading">
              {{ lectureship.template.name }}
            </div>
            <div class="panel-block">
              <p class="content">
                Sie sind <strong>{{ levels[lectureship.level].name }}</strong> für
                <i>{{ lectureship.template.name }}</i>.
              </p>
            </div>
            <div class="panel-block">
              <b-collapse :open="false" class="is-fullwidth"
                          v-if="!!lectureship.template.description">
                <div slot="trigger" slot-scope="props">
                  Beschreibung
                  <a>
                    <b-icon
                        :icon="props.open ? 'menu-up' : 'menu-down'">
                    </b-icon>
                  </a>
                </div>
                <div class="content m-t-md"
                     v-html="$options.filters.marked(lectureship.template.description)"></div>
              </b-collapse>
              <div class="has-text-grey" v-else>Keine Beschreibung</div>
            </div>
            <div class="panel-block">
              <b-collapse v-if="lectureship.template.uploads.length > 0" :open="false"
                          class="is-fullwidth">
                <div slot="trigger" slot-scope="props">
                  Downloads
                  <a>
                    <b-icon
                        :icon="props.open ? 'menu-up' : 'menu-down'">
                    </b-icon>
                  </a>
                </div>
                <div class="m-t-md">
                  <div v-for="upload in lectureship.template.uploads" :key="upload.id">
                    <a :href="`${$config.API_DOWNLOAD_URL}/${upload.id}`">{{ upload.fileName }}</a>
                  </div>
                </div>
              </b-collapse>
              <div class="has-text-grey" v-else>Keine Downloads</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GET_LECTURESHIPS_QUERY from '../graphql/EaMyLectureships.gql'
import MarkdownSetting from "../components/MarkdownSetting";
import {LECTURESHIP_LEVELS} from "@/constants";
import SeminarStatus from "../components/SeminarStatus";

export default {
  name: "Lectureships",
  components: {SeminarStatus, MarkdownSetting},
  data() {
    return {
      EaMyLectureships: [],
      isLoading: 0,
      levels: LECTURESHIP_LEVELS,
      showCurrent: true
    }
  },
  apollo: {
    EaMyLectureships: {
      query: GET_LECTURESHIPS_QUERY,
      loadingKey: 'isLoading'
    }
  },
  computed: {
    LectureshipSeminars() {
      return this.EaMyLectureships.reduce((accu, lec) => [...accu, ...lec.seminars], []).filter((s) => {
        let isCurrent = s.status === 'PUBLIC' || s.status === 'INVITED';
        return this.showCurrent ? isCurrent : !isCurrent;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.panel {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

.panel-block {
  background-color: white;
}

.is-fullwidth {
  width: 100%;
}
</style>