<template>
    <div>
        <seminar-list/>
    </div>
</template>

<script>
import SeminarList from "../components/SeminarList";
export default {
  name: "Seminars",
  components: {SeminarList}
}
</script>

<style scoped>

</style>