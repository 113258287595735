export let hasServerError = {
  data: function () {
    return {
      serverError: null
    }
  },
  methods: {
    hasValidationError(message) {
      let flatten = a => Array.isArray(a) ? [].concat(...a.map(flatten)) : a instanceof Object ? flatten(Object.entries(a)) : a;
      return !!this.serverError.graphQLErrors && flatten(this.serverError.graphQLErrors).find(element => element === message)
    },
  }
}