<template>
  <div v-if="$config.SHOW_SEMINAR_REGISTER">
    <button class="is-loading button is-rounded dw8" v-if="$apollo.loading"></button>
    <button class="button is-primary is-rounded"
            @click="book"

            v-else-if="!isSignedUp && seminar.canRegisterParticipation">
      Zur Anmeldung
    </button>
    <button class="button is-rounded" disabled="" v-else-if="isSignedUp">
      <b-icon icon="check"></b-icon>
      <span>Angemeldet</span>
    </button>
    <div v-else></div>
    <seminar-signup-modal :active="true" :seminar-id="seminar.id" @close="modalActive = false" v-if="modalActive"/>
  </div>
</template>
<script>
import GET_MY_EDUCATION from '../graphql/EaMyEducation.gql'
import SeminarSignupModal from "./SeminarSignupModal";

export default {
  name: "SeminarSignupButton",
  components: {SeminarSignupModal},
  props: ['seminar'],
  data() {
    return {
      modalActive: false,
      EaMyParticipations: []
    }
  },
  methods: {
    book() {
      if (this.$store.getters.loggedIn) {
        this.modalActive = true
      } else {
        const loginPath = window.location.pathname;
        this.$router.push({name: 'Login', query: {from: loginPath}})
      }
    }
  },
  computed: {
    isSignedUp() {
      return this.seminar.isParticipating
    }
  },
}
</script>

<style scoped>
.modal-card-foot {
  justify-content: flex-end;
}

.modal-card-title {
  flex-shrink: 1;
}
</style>