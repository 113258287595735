import { render, staticRenderFns } from "./MySeminars.vue?vue&type=template&id=272c3713&scoped=true&"
import script from "./MySeminars.vue?vue&type=script&lang=js&"
export * from "./MySeminars.vue?vue&type=script&lang=js&"
import style0 from "./MySeminars.vue?vue&type=style&index=0&id=272c3713&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272c3713",
  null
  
)

export default component.exports