import apollo from '../../apollo'
import GET_PROFILE_QUERY from '../../graphql/EaProfile.gql'
import LOGOUT_MUTATION from '../../graphql/EaLogout.gql'
import LOGIN_MUTATION from '../../graphql/EaLogin.gql'
import router from '../../router'

const defaultState = {
  user: {
    id: null,
    isLecturer: false,
    isAnalyzer: false,
    fullName: '',
    mitgliedernummer: ''
  }
}

const profileQuery = apollo.watchQuery({query: GET_PROFILE_QUERY})

export default {
  state: {...defaultState},
  actions: {
    async getUser(context) {
      return new Promise((resolve, reject) => {
        profileQuery.subscribe(({data, loading}) => {
          if (data.Profile === null) {
            context.commit('reset')
          } else {
            context.commit('setProfile', data.Profile)
          }
          resolve()
        });
      })
    },
    async login(context, {email, password, redirectTo = "/dashboard"}) {
      // calls the api
      return apollo.mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          username: email,
          password: password
        }
      }).then(() => {
        profileQuery.refetch().then(() => {
          router.replace(redirectTo)
        })
      }).catch(e => {
        // throw Login Error
        //console.log({e},e,e.graphQLErrors)
        if (e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message === "Authentication Failed") {
          throw new Error('Login Error')
        } else if (e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message === "Too many login attempts") {
          throw new Error('Too many login attempts')
        } else if (e && e.networkError && e.networkError) {
          console.log({e})
          throw new Error('Network Error')
        } else {
          console.log({e})
          throw new Error('Server Error')
        }
      });
    },
    async logout(context) {
      apollo.mutate({
        mutation: LOGOUT_MUTATION
      }).then(() => {
        location.reload()
      });
    }
  },
  mutations: {
    reset(state) {
      Object.assign(state, defaultState)
    },
    setProfile(state, profile) {
      state.user = {
        ...defaultState.user,
        ...profile
      }
    }
  },
  getters: {
    loggedIn: (state) => {
      return state.user.id !== null
    },
    hasPermission: (state) => (name) => {
      return !!state.user.permissions.find(permission => permission.name === name)
    }
  }
}
