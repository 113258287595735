<template>
  <auth-base>
    <h1>Passwort zurücksetzen</h1>
    <div class="has-text-left">
      <div v-if="!isSent">
        <b-message has-icon type="is-danger" v-if="showError">
          <b>Oh, nein.</b><br/>
          Leider ist ein Fehler augetreten.
        </b-message>

        <b-field label="E-Mail Adresse">
          <b-input placeholder="E-Mail-Adresse" size="is-medium" type="email" v-model="email"/>
        </b-field>
        <div class="has-text-centered">
          <a :class="{'is-loading' : isLoading}" @click="send" class="button is-block is-info is-medium">Passwort
            zurücksetzen</a>
        </div>

      </div>
      <b-message v-else-if="showNotFound" has-icon type="is-danger">
        <b>Nicht gefunden</b><br/>
        Leider konnte kein Account mit Deiner E-Mail-Adresse gefunden werden.<br/>
        <router-link :to="{name:'Register',params: {email: this.email}}" style="line-height: 2;">Zur Registrierung
        </router-link>
      </b-message>
      <b-message has-icon type="is-success" v-else>
        <b>Alles klar!</b><br/>
        An Deine E-Mail-Adresse wurde ein Link zum Zurücksetzen des Passwords versandt.
      </b-message>
    </div>
    <div class="has-text-grey m-t-lg has-text-weight-normal">
      <p>Sie haben Ihr Passwort doch nicht vergessen?<br/>
        <router-link to="/login">Zum Login</router-link>
      </p>
      <p v-if="$config.SHOW_REGISTER" class="m-t-lg">Sie haben noch keinen Account?<br/>
        <router-link to="/register">Zur Registrierung</router-link>
      </p>
      <p v-if="$config.SHOW_SUPPORT_FORM" class="m-t-lg">
        Sie haben Ihre E-Mail-Adresse vergessen?<br/>
        <router-link
            :to="{name:'Support', params:{message:'Hallo,\nIch weiß nicht mehr welche E-Mail-Adresse von mir beim VdK hinterlegt ist.'}}">
          Supportanfrage stellen
        </router-link>
      </p>
    </div>

  </auth-base>
</template>

<script>
import AuthBase from './AuthBase'
import SEND_PASSWORD_RESET_MUTATION from '../../graphql/EaSendPasswordReset.gql'

export default {
  components: {AuthBase},
  name: 'send-password-reset',
  props: {email: {type: String, default: ''}},
  data() {
    return {
      email: this.email,
      isSent: false,
      isLoading: false,
      showError: false,
      showNotFound: false,
    }
  },
  methods: {
    send() {
      this.isLoading = true
      this.showNotFound = false
      this.showError = false

      this.$apollo.mutate({
        mutation: SEND_PASSWORD_RESET_MUTATION,
        variables: {
          email: this.email
        }
      }).then(({data}) => {
        if (!data.EaSendPasswordReset) {
          this.showNotFound = true
        }
        this.isSent = true
        this.isLoading = false
      }).catch(() => {
        this.showError = true
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>