<template>
  <div>
    <h2>{{ appointments.length > 1 ? "Termine" : "Termin" }}</h2>
    <template v-if="appointments.length === 0">
      Noch kein Termin
    </template>
    <template>
      <div v-for="a in mappedAppointments">
        <template v-if="a.isSameDay">
          <span class="day">{{ a.begin | moment('ddd') }}</span>{{ a.begin | moment('DD.MM.YYYY  HH:mm') }} -
          {{ a.end | moment('HH:mm [Uhr]') }}
        </template>
        <template v-else>
          {{ a.begin | moment('dddd DD.MM.YYYY HH:mm [Uhr]') }} - {{ a.end | moment('dddd DD.MM.YYYY HH:mm [Uhr]') }}
        </template>
        <template v-if="showPlaceDetail">
          <span v-if="a.place !== null">
            ({{ a.place.name }})
          </span>
          <span v-else>
            (Noch kein Ort)
          </span>
        </template>

      </div>
    </template>
    <h2>{{ places.length === 1 ? 'Ort' : 'Orte' }}</h2>
    <div v-if="places.length === 0">
      Noch kein Ort
    </div>
    <div v-for="p in places">
      <div v-if="p.address" class="lines">
        {{ p.address.toLines }}
      </div>
      <div v-else>{{ p.name }}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "SeminarAppointments",
  props: ['appointments'],
  computed: {
    mappedAppointments() {
      return this.appointments.map(a => {
        let begin = moment(a.begin);
        let end = moment(a.end);
        return {
          begin,
          end,
          isSameDay: begin.isSame(end, 'day'),
          ...a
        }
      })
    },
    showPlaceDetail() {
      return this.places.length > 1 || this.places.length === 1 && this.appointments.findIndex(a => a.place === null) !== -1
    },
    places() {
      return this.appointments.reduce((places, current) => {
        if (current.place !== null && places.findIndex(p => p.id === current.place.id) === -1) {
          places.push(current.place);
        }
        return places;
      }, [])
    },
  }
}
</script>

<style scoped>
.day {
  display: inline-block;
  width: 1.75em;
}

.lines {
  white-space: pre-line;
}
</style>