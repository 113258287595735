<template>
  <div>
    <auth-base>
      <h1>Hilfe</h1>
      <b-message type="is-danger" v-if="failure">
        <strong>Ein Fehler ist aufgetreten</strong><br/>
        <p v-if="hasValidationError('validation.mitgliedernummer_with_birth')">Die Kombination aus Mitgliedernummer und
          Geburtsdatum konnte nicht gefunden werden</p>
      </b-message>
      <b-message type="is-success" v-if="success">
        <strong>Supportanfrage verschickt</strong><br/>
        Ihre Supportanfrage wurde erfolgreich verschickt.<br/>
        Wir melden uns zeitnah bei Ihnen.
      </b-message>
      <template v-if="!success">
        <b-field :type="errors.mitgliedernummer ? 'is-danger': ''" label="Mitgliedernummer">
          <b-input placeholder="Ihre VdK Mitgliedsnummer" v-model="mitgliedernummer"></b-input>
        </b-field>
        <b-field :type="errors.birth ? 'is-danger': ''" label="Geburtsdatum">
          <b-input placeholder="Ihr Geburtsdatum" type="date" v-model="birth"></b-input>
        </b-field>
        <b-field :type="errors.supportMessage ? 'is-danger': ''" label="Nachricht">
                    <textarea class="textarea" placeholder="Ihre Nachricht" v-model="supportMessage"
                              style="min-height:300px;"></textarea>
        </b-field>
        <button :class="{'is-loading': isLoading}" @click="sendMessage" class="button is-primary m-t-md">
          Absenden
        </button>
      </template>
    </auth-base>
  </div>
</template>

<script>
  import AuthBase from "./auth/AuthBase";
  import SUPPORT_MUTATION from "../graphql/EaSupport.gql"
  import {hasServerError} from "../mixins";

  export default {
    name: "Support",
    components: {AuthBase},
    props: ['message'],
    mixins: [hasServerError],
    data() {
      return {
        supportMessage: this.message,
        mitgliedernummer: '',
        birth: null,
        success: false,
        failure: false,
        isLoading: false,
        errors: {}
      }
    },
    methods: {
      validate() {
        this.errors = {}
        this.errors.mitgliedernummer = !this.mitgliedernummer
        this.errors.birth = !this.birth
        this.errors.supportMessage = !this.supportMessage

        for (let i in this.errors) {
          if (this.errors[i] === true) {
            return false
          }
        }
        return true
      },
      sendMessage() {
        if (!this.validate()) return
        this.isLoading = true

        this.$apollo.mutate({
          mutation: SUPPORT_MUTATION,
          variables: {
            data: {
              mitgliedernummer: this.mitgliedernummer,
              birth: this.birth,
              message: this.supportMessage
            }
          }
        }).then(() => {
          this.failure = false
          this.isLoading = false
          this.success = true
        }).catch(error => {
          this.isLoading = false
          this.failure = true
          this.serverError = error
        })
      }
    }
  }
</script>

<style scoped>

</style>