<template>
  <div><h1>Entschuldigung</h1>
    leider konnteunter der angegebenen Adresse nichts gefunden werden.
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>