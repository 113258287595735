<template>
  <div class="m-t-md m-b-lg" v-if="!isLoading">
    <div class="very-small-container">
      <div class="headline">Meine Daten</div>
      <div class="field">
        <label class="label">Name</label>
        {{ Profile.fullName }}
      </div>
      <div class="m-t-md">
        <label class="label is-marginless">E-Mail</label>
        <div class="is-inline-flex">{{ Profile.email }}</div>
      </div>
      <div class="headline">{{ $config.SHOW_BILLING_ADDRESSES ? 'Kontakt- & Rechnungsadresse' : 'Kontaktadresse' }}
      </div>
      <editable v-slot="{editing, mutate, edit, data, editData, loading, errors, isDifferent}"
                :data="{contactAddress: Profile.contactAddress || {}, billingAddress: Profile.billingAddress || null }"
                :excludeKeys="['contactAddress.toString','billingAddress.toString']"
                :mutation="require('@/graphql/updateEaProfile.gql')"
                include-all
                input-arg="profile">
        <template v-if="!editing">

          <div v-for="(ad, index) in data" v-if="ad !== null" class="mb-4">
            <label class="label mb-0">{{ index === 'contactAddress' ? 'Adresse' : 'Rechnungsanschrift' }}</label>
            {{ ad.toString }}
            <a v-if="!editing && $config.SHOW_EDITABLE_ADDRESSES" class="ml-2" @click="edit">
              <b-icon icon="pencil"></b-icon>
            </a>
          </div>
          <div
              v-if="data && data.contactAddress && (data.billingAddress ? data.billingAddress.email : data.contactAddress.email)"
              class="mb-4">
            <label class="label mb-0">Rechnungs E-Mail</label>
            {{ data.billingAddress ? data.billingAddress.email : data.contactAddress.email }}
          </div>
        </template>
        <template v-else>
          <label class="label mb-0">Adresse</label>
          <div class="mb-4 max-w-500 mt-4" @keydown.enter="mutate">
            <b-field :type="{'is-danger' :errors['Profile.contactAddress.name']}" label="Name / Firma"
                     label-position="on-border">
              <b-input v-model="editData.contactAddress.name" placeholder="Max Mustermann"></b-input>
            </b-field>
            <b-field :type="{'is-danger' :errors['Profile.contactAddress.addition1']}" grouped
                     label="Adresszusatz  (optional)"
                     label-position="on-border">
              <b-input v-model="editData.contactAddress.addition1"
                       expanded
                       placeholder="z.B. Abteilung oder Ansprechpartner"/>
              <button class="button" @click="editData.contactAddress.addition2 = ''">
                <b-icon icon="plus"></b-icon>
              </button>
            </b-field>
            <b-field v-if="editData.contactAddress.addition2 !== null"
                     :type="{'is-danger' :errors['Profile.contactAddress.addition2']}" grouped
                     label="2. Adresszusatz  (optional)"
                     label-position="on-border">
              <b-input v-model="editData.contactAddress.addition2" expanded placeholder="z.B. Ansprechpartner"/>
              <button class="button" @click="editData.contactAddress.addition2 = null">
                <b-icon icon="minus"></b-icon>
              </button>
            </b-field>
            <b-field :type="{'is-danger' :errors['Profile.contactAddress.street']}" label="Straße"
                     label-position="on-border">
              <b-input v-model="editData.contactAddress.street" placeholder="Musterstraße 123"></b-input>
            </b-field>
            <b-field grouped>
              <b-field :type="{'is-danger' :errors['Profile.contactAddress.zip']}" label="PLZ"
                       label-position="on-border"

                       style="max-width: 8em;">
                <b-input v-model="editData.contactAddress.zip" :has-counter="false" :maxlength="5"
                         placeholder="12345"></b-input>
              </b-field>
              <b-field :type="{'is-danger' :errors['Profile.contactAddress.city']}" expanded label="Ort"
                       label-position="on-border">
                <b-input v-model="editData.contactAddress.city" placeholder="Stadt"></b-input>
              </b-field>
            </b-field>
            <b-field :type="{'is-danger' :errors['Profile.contactAddress.email']}" expanded
                     label="Rechnungs E-Mail (optional)"
                     label-position="on-border"
            >
              <b-input v-model="editData.contactAddress.email" :disabled="editData.billingAddress !== null"
                       :placeholder="Profile.email"/>
            </b-field>
            <b-field v-if="$config.SHOW_BILLING_ADDRESSES" class="mt-4">
              <b-checkbox :value="editData.billingAddress !== null"
                          @input="(val) => val ? editData.billingAddress = {addition2: null} : editData.billingAddress = null">
                Abweichende Rechnungsanschrift ?
              </b-checkbox>
            </b-field>
            <!--<b-field label="Abweichende Rechnungsanschrift">
              <b-switch :value="editData.billingAddress !== null"
                        @input="(val) => val ? editData.billingAddress = {} : editData.billingAddress = null"></b-switch>
            </b-field>-->
            <template v-if="editData.billingAddress !== null">
              <label class="label mb-4">Rechnungsanschrift</label>
              <b-field :type="{'is-danger' :errors['Profile.billingAddress.name']}" label="Name / Firma"
                       label-position="on-border">
                <b-input v-model="editData.billingAddress.name" placeholder="Max Mustermann"></b-input>
              </b-field>

              <b-field :type="{'is-danger' :errors['Profile.billingAddress.addition1']}" grouped
                       label="Adresszusatz  (optional)"
                       label-position="on-border">
                <b-input v-model="editData.billingAddress.addition1"
                         expanded
                         placeholder="z.B. Abteilung oder Ansprechpartner"/>
                <button class="button" @click="editData.billingAddress.addition2 = ''">
                  <b-icon icon="plus"></b-icon>
                </button>
              </b-field>
              <b-field v-if="editData.billingAddress.addition2 !== null"
                       :type="{'is-danger' :errors['Profile.billingAddress.addition2']}" grouped
                       label="2. Adresszusatz  (optional)"
                       label-position="on-border">
                <b-input v-model="editData.billingAddress.addition2" expanded placeholder="z.B. Ansprechpartner"/>
                <button class="button" @click="editData.billingAddress.addition2 = null">
                  <b-icon icon="minus"></b-icon>
                </button>
              </b-field>
              <b-field :type="{'is-danger' :errors['Profile.billingAddress.street']}" label="Straße und Hausnummer"
                       label-position="on-border">
                <b-input v-model="editData.billingAddress.street" placeholder="Musterstraße 123"></b-input>
              </b-field>
              <b-field grouped>
                <b-field :type="{'is-danger' :errors['Profile.billingAddress.zip']}" label="PLZ"
                         label-position="on-border"
                         style="max-width: 8em;">
                  <b-input v-model="editData.billingAddress.zip" :has-counter="false" :maxlength="5"
                           placeholder="12345"></b-input>
                </b-field>
                <b-field :type="{'is-danger' :errors['Profile.billingAddress.city']}" expanded label="Ort"
                         label-position="on-border">
                  <b-input v-model="editData.billingAddress.city" placeholder="Stadt"></b-input>
                </b-field>
              </b-field>
              <b-field :type="{'is-danger' :errors['Profile.billingAddress.email']}" expanded
                       label="Rechnungs E-Mail (optional)"
                       label-position="on-border">
                <b-input v-model="editData.billingAddress.email" :placeholder="Profile.email"></b-input>
              </b-field>
            </template>
          </div>
        </template>

        <div v-if="editing" class="control">
          <button :class="{'is-loading': loading}" :disabled="!isDifferent" class="button is-success" @click="mutate">
            Änderungen
            speichern
          </button>
          <button class="button is-danger ml-2" @click="edit(false)">Abbrechen</button>
        </div>
      </editable>
      <template v-if="$config.SHOW_EDITABLE_ACCOMMODATION || $config.SHOW_EDITABLE_ACCESSIBILITY">
        <div class="headline">Barrierefreiheit & Verpflegung</div>
        <p>Für eine bestmögliche Unterbringung können Sie hier einige Angaben machen.</p>

        <editable v-slot="{editing, mutate, editData, loading, isDifferent, edit}"
                  :data="{accommodationTags: Profile.accommodationTags, accessibilityTags: Profile.accessibilityTags}"
                  :include-all="true"
                  :mutation="require('@/graphql/updateEaProfile.gql')"
                  input-arg="profile">
          <div class="columns mt-1">
            <div v-if="$config.SHOW_EDITABLE_ACCESSIBILITY" class="column">
              <label class="label mb-4">Angaben zur Barrierefreiheit</label>
              <div v-for="(description, tag) in $config.accessibilityTags" class="field">
                <b-checkbox v-model="editData.accessibilityTags" :native-value="tag">{{ description }}</b-checkbox>
              </div>
            </div>
            <div v-if="$config.SHOW_EDITABLE_ACCOMMODATION" class="column">
              <label class="label mb-4">Angaben zur Verpflegung</label>
              <div v-for="(description, tag) in $config.accommodationTags" class="field">
                <b-checkbox v-model="editData.accommodationTags" :native-value="tag">{{ description }}</b-checkbox>
              </div>
            </div>
          </div>
          <template v-if="isDifferent">
            <button :class="{'is-loading': loading}" class="button is-success" @click="mutate">
              Änderungen
              speichern
            </button>
            <button class="button is-danger ml-2" @click="edit(false)">Abbrechen</button>
          </template>
        </editable>
      </template>
      <div class="headline">Passwort ändern</div>
      <password-changer class="m-t-md"/>
      <div class="headline has-text-danger">Account löschen</div>
      <div class="has-text-danger">
        Wenn Sie Ihren Benutzeraccount löschen, haben Sie danach keinen Zugang mehr zum internen Bereich der
        Ehrenamtsakademie.<br/>
        <delete-profile-button class="m-t-md "/>
      </div>
    </div>
  </div>
</template>

<script>
import GET_PROFILE_QUERY from '../graphql/EaProfile.gql'
import PasswordChanger from '../components/PasswortChanger'
import DeleteProfileButton from '../components/DeleteProfileButton'
import Editable from "@/components/Editable";

export default {
  components: {
    DeleteProfileButton,
    PasswordChanger,
    Editable
  },
  name: 'user-settings',
  data() {
    return {
      Profile: {},
      isLoading: 0,
      newPassword: {
        old: '',
        new: '',
        repeat: ''
      },
      newMail: ''
    }
  },
  computed: {
    hasBillingAddress() {
      return this.Profile.billingAddress !== null
    }
  },
  apollo: {
    Profile: {
      query: GET_PROFILE_QUERY,
      loadingKey: 'isLoading'
    }
  }
}
</script>

<style scoped>
.max-w-500 {
  max-width: 500px;
}
</style>
