<template>
    <div>
        <div :class="[obj.class]" v-if="status === obj.key" v-for="obj in statuslist" :key="obj.key">{{obj.name}}</div>
    </div>
</template>

<script>
import {SEMINAR_STATUS} from "../constants";

export default {
  name: 'seminar-status',
  props: ['status'],
  data(){
    return {
      statuslist: SEMINAR_STATUS
    }
  }
}
</script>

<style scoped>
.status_label{
    width:8em;
    text-align: center;
}
</style>
