<template>
    <div class="m-b-lg" v-if="future.length > 0">
        Ihr Seminar
        <router-link :to="{name: 'Seminar', params: {id: future[0].seminar.id}}">
            {{future[0].seminar.template.name}}
        </router-link>
        beginnt <span class="has-text-weight-bold">{{ future[0].seminar.date | moment('from', 'now')}}</span>
        am {{future[0].seminar.date | moment('DD.MM.YYYY')}}.
    </div>
</template>

<script>
import GET_MY_EDUCATION_QUERY from '../graphql/EaMyEducation.gql'
import moment from 'moment'

export default {
  name: "MyNextSeminar",
  data() {
    return {
      EaMyParticipations: []
    }
  },
  apollo: {
    EaMyParticipations: {
      query: GET_MY_EDUCATION_QUERY
    }
  },
  computed: {
    future() {
      return this.EaMyParticipations.filter((p) => {
        return p.status !== 'CANCELED' && (p.seminar.status === 'PUBLIC' || p.seminar.status === 'INVITED')
      }).filter((p) => {
        return moment(p.seminar.date).isAfter(moment())
      }).sort(function(a, b) {
        return new Date(a.seminar.date) - new Date(b.seminar.date);
      });
    },
    toNow() {
      if (this.future.length === 0) return '';
      return moment(this.future[0].seminar.date).fromNow()
    }
  }
}
</script>

<style scoped>

</style>