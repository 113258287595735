<template>
    <div class="home">
        <MarkdownSetting id="eaStartpage" />
    </div>
</template>

<script>
import MarkdownSetting from "../components/MarkdownSetting";

export default {
  name: 'home',
  components: {MarkdownSetting}
}
</script>
