<template>
  <div class="m-t-lg">
    <b-message type="is-success" v-if="success">
      <strong>Ihr Account wurde erfolgreich freigeschaltet.</strong><br/> Sie können sich nun einloggen.<br/>
      <router-link :to="{name: 'Login'}" class="button m-t-md">Zum Login</router-link>
    </b-message>
    <b-message type="is-danger" v-if="failure">
      Leider ist ein Fehler aufgetreten.
    </b-message>
  </div>
</template>

<script>
  import AuthBase from "./AuthBase";
  import ACTIVATE_USER_MUTATION from "../../graphql/EaActivateUser.gql"

  export default {
    name: "Activate",
    components: {AuthBase},
    props: ['token'],
    data() {
      return {
        isLoading: false,
        success: false,
        failure: false
      }
    },
    methods: {
      activate() {
        this.isLoading = true
        this.$apollo.mutate({
          mutation: ACTIVATE_USER_MUTATION,
          variables: {
            token: this.token
          }
        }).then(() => {
          this.isLoading = false
          this.success = true
        }).catch(() => {
          this.isLoading = false
          this.failure = true
        })
      }
    },
    mounted() {
      this.activate()
    }
  }
</script>

<style scoped>

</style>