<template>
  <div class="participations">
    <div class="columns">
      <div class="column">
        <h2>Seminarübersicht</h2>
      </div>
      <div class="column is-one-half-desktop">
        <b-field horizontal label="">
          <b-input placeholder="Suchbegriff eingeben" v-model="searchQuery"></b-input>
        </b-field>
      </div>
    </div>
    <b-table :current-page="page"
             :currentPage="parseInt($route.query.page) || 1"
             :data="filteredSeminars"
             :default-sort="[$route.query.sField || 'date', $route.query.sOrder || 'asc']"
             :loading="!!isLoading"
             @page-change="onPageChange"
             @sort="onSort"
             class="table-has-frame"
             paginated
             sortable
             :mobile-cards="false"
             striped>
      <b-table-column v-slot="props" class="dw6 nowrap" field="date" label="Datum" sortable>
        {{ props.row.date | moment('DD.MM.YYYY') }}
      </b-table-column>
      <b-table-column v-slot="props" class="dw6 nowrap" field="date" label="Uhrzeit" sortable>
        {{ props.row.date | moment('HH:mm') }}
      </b-table-column>
      <b-table-column v-slot="props" class="dw17" field="template.name" label="Name" sortable>
        <router-link :to="{name: 'Seminar', params: {id: props.row.id}}">{{ props.row.template.name }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="placeName" label="Ort" sortable>
        {{ props.row.placeName }}
      </b-table-column>
      <b-table-column v-if="$config.SHOW_DISTRICTS" v-slot="props" class="dw9" field="districtName" label="Bezirk"
                      sortable>
        {{ props.row.districtName }}
      </b-table-column>
      <b-table-column v-if="$config.SHOW_PRICE" v-slot="props" field="price" label="Preis" sortable width="5em">
        <seminar-price :price="props.row.price"/>
      </b-table-column>
      <b-table-column v-slot="props" class="dw1" field="freePlaces" label="Plätze" numeric sortable>
        <b-tooltip v-if="props.row.freePlaces > 3" label="Plätze verfügbar"><span
            class="tag freePlaces is-success"></span></b-tooltip>
        <b-tooltip v-else-if="props.row.freePlaces < 1" label="Ausgebucht"><span
            class="tag freePlaces is-danger"></span></b-tooltip>
        <b-tooltip v-else label="Wenige Plätze verfügbar"><span class="tag freePlaces is-warning"></span></b-tooltip>
      </b-table-column>
      <b-table-column v-if="$config.SHOW_SEMINAR_REGISTER" v-slot="props" class="dw8">
        <seminar-signup-button :seminar="props.row"/>
      </b-table-column>

      <div class="card-content has-text-centered" slot="empty" v-if="!isLoading">
        Aktuell gibt es keine ausstehenden Seminare.
      </div>
    </b-table>
  </div>
</template>

<script>
import GET_EA_SEMINARS from '../graphql/EaSeminars.gql'
import SeminarSignupButton from "./SeminarSignupButton";
import SeminarPrice from "@/components/SeminarPrice";

export default {
  name: "SeminarList",
  components: {SeminarPrice, SeminarSignupButton},
  data() {
    return {
      EaSeminars: [],
      isLoading: 0,
      page: parseInt(this.$route.query.page) || 1,
      searchQuery: this.$route.query.query || ''
    }
  },
  apollo: {
    EaSeminars: {
      query: GET_EA_SEMINARS,
      loadingKey: 'isLoading'
    }
  },
  watch: {
    searchQuery(searchString) {
      this.onSearch(searchString)
    }
  },
  methods: {
    onSearch(query) {
      this.$router.replace({name: this.$route.name, query: {query: query, page: 1}})
    },
    onSort(field, order) {
      if (typeof this.$router.query !== "undefined") {
        if (this.$router.query.sField === field && this.$router.query.sOrder === order) return;
      }
      this.$router.replace({name: this.$route.name, query: {query: this.searchQuery, sField: field, sOrder: order}})
    },
    onPageChange(page) {
      this.$router.replace({name: this.$route.name, query: {...this.$route.query, page: page}})
    }
  },
  computed: {
    filteredSeminars() {
      if (!this.$route.query.query) return this.EaSeminars;
      return this.EaSeminars.filter(s => {
        let longString = s.template.name + " " + s.districtName + s.placeName;
        return longString.toLowerCase().indexOf(this.$route.query.query.toLowerCase()) !== -1
      });
    }
  }
}
</script>

<style scoped>
.participation {
  background: whitesmoke;
  margin: 0.5rem 0;
  padding: 0.5rem;
  text-align: left;
}

.participations {


}

.nowrap {
  white-space: nowrap;
}

.freePlaces {
  width: 2em;
  border-radius: 50%;
  font-size: 0.9em;
}
</style>