<template>
  <auth-base>
    <div class="has-text-left">
      <h1>Registrierung</h1>
      <div class="m-t-md">
        <template v-if="!successMessage">
          <b-message v-if="!!errorMessage" type="is-danger"><b>Oh, nein.</b><br/>{{ errorMessage }}<br/><br/>
            <div class="has-text-weight-bold">
              <p v-if="hasValidationError('validation.exists')">
                Die angegebene E-Mail-Adresse wurde nicht gefunden.
              </p>
              <p v-else-if="hasValidationError('validation.unique')">
                Es gibt mehr als ein Mitglied mit der angegebenen E-Mail-Adresse.<br/><br/>
                <router-link v-if="$config.SHOW_SUPPORT_FORM" :to="{name:'Support', params:{
                                                message:'Hallo,\ndie E-Mail-Adresse die ich beim VdK Hessen-Thüringen hinterlegt habe gibt es doppelt.\nStattdessen möchte ich eine andere E-Mail-Adresse hinterlegen.\n\nAlte E-Mail-Adresse: '+newUser.email+'\nNeue E-Mail-Adresse: '}}"
                             class="has-text-weight-normal">
                  Klicken um Supportanfrage zu stellen
                </router-link>
              </p>
              <p v-else-if="hasValidationError('validation.already_registered')">
                Die angegebene E-Mail-Adresse wird bereits verwendet.
              </p>
            </div>
          </b-message>

          <b-field :type="errors.email ? 'is-danger': ''" label="E-Mail Adresse"
                   :message="$config.SHOW_REGISTER_NEW ? '': 'Ihre beim VdK Hessen-Thüringen hinterlegte E-Mail-Adresse'">
            <b-input :disabled="isNewMember" placeholder="E-Mail Adresse" type="email" v-model="newUser.email"/>
          </b-field>
          <b-field :type="errors.passwordLength ? 'is-danger': ''" label="Passwort"
                   message="mindestens 8 Zeichen lang">
            <b-input :disabled="isNewMember" placeholder="Passwort" type="password" v-model="newUser.password"/>
          </b-field>
          <b-field :message="errors.passwordRepeat ? 'Die Passwörter stimmen nicht überein' : ''"
                   :type="errors.passwordRepeat ? 'is-danger': ''"
                   label="Passwort wiederholen">
            <b-input :disabled="isNewMember" placeholder="Passwort wiederholen" type="password"
                     v-model="newUser.passwordRepeat"/>
          </b-field>
          <b-field :message="errors.privacy ? 'Du musst die Datenschutzerklärung akzeptieren' : ''"
                   :type="errors.privacy ? 'is-danger': ''" class="m-t-md"
                   label="Datenschutzerklärung">
            <b-checkbox type="is-info" v-model="acceptPrivacy">
              Ich akzeptiere die <a href="//www.ehrenamtsakademie.de/datenschutz"
                                    target="_blank">Datenschutzerklärung</a>
            </b-checkbox>
          </b-field>
          <template v-if="isNewMember">
            <h2>Persönliche Daten</h2>
            <b-field :type="{'is-danger' : hasDeepValidationError('firstName')}" label="Vorname">
              <b-input placeholder="Vorname" v-model="newMember.firstName"></b-input>
            </b-field>
            <b-field :type="{'is-danger' : hasDeepValidationError('lastName')}" label="Nachname">
              <b-input placeholder="Nachname" v-model="newMember.lastName"></b-input>
            </b-field>
            <div class="field">
              <a v-if="!hasKundennummer" class="is-block" @click="hasKundennummer = true">Sie haben bereits eine
                Kundennummer?</a>
              <a v-if="!hasMitgliedernummer" class="is-block" @click="hasMitgliedernummer = true">Sie haben eine
                VdK-Mitgliedsnummer?</a>
            </div>
            <b-field v-if="hasKundennummer"
                     label="Kundennummer"
                     message="Ihre Kundennummer finden Sie auf Einladungs- bzw. Informationsschreiben.">
              <b-input v-model="newMember.registrationInfo.kundennummer" placeholder="Kundennummer"></b-input>
            </b-field>
            <b-field v-if="hasMitgliedernummer" label="VdK Mitgliedsnummer"
                     message="Ihre Mitgliedernummer im VdK Hessen-Thüringen.">
              <b-input v-model="newMember.registrationInfo.mitgliedernummer" placeholder="Mitgliedsnummer"></b-input>
            </b-field>
            <h3>Angaben zur Unterbringung</h3>
            <b-field grouped>
              <div class="field is-expanded">
                <label class="label">Barrierefreiheit</label>
                <div v-for="(description, tag) in accessibility" class="field is-block">
                  <b-checkbox v-model="newMember.accessibility" :native-value="tag">{{ description }}</b-checkbox>
                </div>
                <div class="field is-block">
                  <b-checkbox v-model="hasOtherAccessibility">Sonstiges</b-checkbox>
                </div>
              </div>
              <div class="field is-expanded">
                <label class="label">Verpflegung</label>
                <div v-for="(description, tag) in accommodation" class="field is-block">
                  <b-checkbox v-model="newMember.accommodation" :native-value="tag">{{ description }}</b-checkbox>
                </div>
                <div class="field is-block">
                  <b-checkbox v-model="hasOtherAccommodation">Sonstiges</b-checkbox>
                </div>
              </div>
            </b-field>
            <b-field v-if="hasOtherAccessibility" label="Sonstige Barrierefreiheit">
              <b-input v-model="newMember.registrationInfo.otherAccessibility"
                       placeholder="Zusätzliche Angabe zur Barrierefreiheit"></b-input>
            </b-field>
            <b-field v-if="hasOtherAccommodation" label="Sonstige Verpflegung">
              <b-input v-model="newMember.registrationInfo.otherAccommodation"
                       placeholder="Zusätzliche Angabe zur Verpflegung"></b-input>
            </b-field>
            <h2>Unternehmen / Kommune</h2>
            <b-field :type="{'is-danger' : hasDeepValidationError('functionId')}" label="Ihre Funktion">
              <b-select v-model="newMember.functionId" expanded placeholder="Funktion auswählen">
                <!--<option :value="null">keine Funktion</option>-->
                <option
                    v-for="fun in EaAssociationFunctions.filter(f => f.isRegistrable).slice().sort((a,b) => a.name.localeCompare(b.name)).sort((a,b) => (a.name === 'Sonstige') - (b.name === 'Sonstige') )"
                    :value="fun.id">
                  {{ fun.name }}
                </option>
              </b-select>
            </b-field>
            <b-field :type="{'is-danger' : hasDeepValidationError('contactAddress.name')}" label="Name">
              <b-input v-model="newMember.contactAddress.name"
                       placeholder="Name des Unternehmens / der Kommune"></b-input>
            </b-field>
            <b-field :type="{'is-danger' : hasDeepValidationError('contactAddress.addition1')}" label="Addresszusatz">
              <b-input v-model="newMember.contactAddress.addition1"
                       placeholder="Addresszusatz"></b-input>
            </b-field>
            <b-field :type="{'is-danger' : hasDeepValidationError('contactAddress.street')}" expanded
                     label="Straße und Hausnummer">
              <b-input v-model="newMember.contactAddress.street" placeholder="Musterweg 123"></b-input>
            </b-field>
            <b-field grouped>
              <b-field :type="{'is-danger' : hasDeepValidationError('contactAddress.zip')}" label="Postleitzahl"
                       style="width:7em;">
                <b-input v-model="newMember.contactAddress.zip" placeholder="Postleitzahl"></b-input>
              </b-field>
              <b-field :type="{'is-danger' : hasDeepValidationError('contactAddress.city')}" expanded label="Ort">
                <b-input v-model="newMember.contactAddress.city" placeholder="Ort"></b-input>
              </b-field>
            </b-field>
            <b-field :type="{'is-danger' : hasDeepValidationError('phone')}" label="Telefon">
              <b-input placeholder="Telefon" v-model="newMember.phone"></b-input>
            </b-field>
            <a @click="hasInvoiceAddress = !hasInvoiceAddress">
              Abweichende Rechnungsanschrift?
            </a>
            <template v-if="hasInvoiceAddress">
              <h3>Rechnungsadresse</h3>
              <b-field :type="{'is-danger' : hasDeepValidationError('billingAddress.name')}" label="Name">
                <b-input v-model="newMember.billingAddress.name" placeholder="Name"></b-input>
              </b-field>
              <b-field :type="{'is-danger' : hasDeepValidationError('billingAddress.addition1')}" label="Addresszusatz">
                <b-input v-model="newMember.billingAddress.addition1"
                         placeholder="Addresszusatz"></b-input>
              </b-field>
              <b-field :type="{'is-danger' : hasDeepValidationError('billingAddress.street')}"
                       label="Straße und Hausnummer">
                <b-input v-model="newMember.billingAddress.street" placeholder="Musterweg 123"></b-input>
              </b-field>
              <b-field grouped>
                <b-field :type="{'is-danger' : hasDeepValidationError('billingAddress.zip')}" label="Postleitzahl"
                         style="width:7em;">
                  <b-input v-model="newMember.billingAddress.zip" :has-counter="false" maxlength="10"
                           placeholder="12345"></b-input>
                </b-field>
                <b-field :type="{'is-danger' : hasDeepValidationError('billingAddress.city')}" expanded label="Ort">
                  <b-input v-model="newMember.billingAddress.city" placeholder="Ort"></b-input>
                </b-field>
              </b-field>
              <b-field :type="{'is-danger' : hasDeepValidationError('billingAddress.email')}" label="Rechnungs E-Mail">
                <b-input v-model="newMember.billingAddress.email"
                         placeholder="rechnung@email.de"></b-input>
              </b-field>
            </template>
            <h3> Newsletter </h3>
            <b-field>
              <b-checkbox v-model="newMember.registrationInfo.wantsNewsletter">
                Ja, ich möchte das <b>VdK-Bildungsprogramm</b> (Einladungen zu VdK-Arbeitskreisen, Tagungen und
                Seminaren etc.) erhalten.
              </b-checkbox>
            </b-field>
          </template>
          <b-field :message="{'Leider gibt es Probleme mit einem oder mehreren Felder.': hasSomeValidationError()}"
                   :type="{'is-danger' : hasSomeValidationError()}">
          </b-field>
          <a :class="{'is-loading' : isLoading}" @click="registerNew"
             class="button m-t-lg is-block is-primary is-medium m-b-lg"
             v-if="isNewMember">
            Registrierung abschließen
          </a>
          <a :class="{'is-loading' : isLoading}" @click="register"
             class="button m-t-lg is-block is-primary is-medium m-b-lg"
             v-else>
            Registrieren
          </a>


        </template>
        <div class="has-text-centered content" v-else>
          <b-message type="is-success"><b>Ihre Registrierung war erfolgrech.</b><br/>
            An <b>{{ newUser.email }}</b> wurde ein Link zum Aktivieren Ihres Accounts geschickt.
          </b-message>
        </div>
      </div>
    </div>
    <div class="has-text-grey" v-if="!successMessage">
      <p>Sie haben bereits einen Account?<br/>
        <router-link to="/login">Zum Login</router-link>
      </p>
      <p v-if="$config.SHOW_SUPPORT_FORM" class="m-t-lg">
        Sie haben noch keine E-Mail-Adresse beim VdK Hessen-Thüringen hinterlegt?<br/>
        <small>Dann senden Sie uns einfach eine Supportanfrage.</small>
        <br/>
        <router-link
            :to="{name:'Support', params:{message:'Hallo,\nich möchte gerne eine E-Mail-Adresse beim VdK Hessen-Thüringen hinterlegen.\nDie E-Mail Adresse lautet:'}}">
          Supportanfrage stellen
        </router-link>
      </p>
    </div>
  </auth-base>
</template>

<script>
import AuthBase from "./AuthBase";
import REGISTER_EXISTING_MEMBER_MUTATION from '../../graphql/EaRegisterExisting.gql'
import REGISTER_NEW_MEMBER_MUTATION from '../../graphql/EaRegisterNew.gql'
import GET_ASSOCIATION_FUNCTIONS_QUERY from '../../graphql/EaAssociationFunctions.gql'

import {accommodationTags as accessibility, accessibilityTags as accommodation} from '@/config'
import _ from 'lodash'

export default {
  name: "Register",
  components: {AuthBase},
  props: {email: {type: String, default: ''}},
  data() {
    return {
      get: _.get,
      EaAssociationFunctions: [],
      accessibility,
      accommodation,
      newMember: {
        firstName: '',
        lastName: '',
        //title: '',
        //salutation: null,
        phone: '',
        functionId: null,
        contactAddress: {},
        billingAddress: {},
        accessibility: [],
        accommodation: [],
        registrationInfo: {
          otherAccessibility: '',
          otherAccommodation: '',
          wantsNewsletter: false,
        },
      },
      newUser: {
        email: this.email,
        password: '',
        passwordRepeat: ''
      },
      acceptPrivacy: false,
      isNewMember: false,
      hasInvoiceAddress: false,
      hasMitgliedernummer: false,
      hasKundennummer: false,
      hasOtherAccessibility: false,
      hasOtherAccommodation: false,
      successMessage: false,
      errorMessage: '',
      isLoading: false,
      errors: {}
    }
  },
  methods: {
    hasValidationError(message) {
      let errors = _.get(this.serverError, 'graphQLErrors[0].extensions.validation')
      console.log(_.flatten(Object.values(errors)))
      if (_.isEmpty(errors)) return false
      return _.flatten(Object.values(errors)).indexOf(message) !== -1
    },
    hasSomeValidationError() {
      return !_.isEmpty(_.get(this.serverError, `graphQLErrors[0].extensions.validation`))
    },
    hasDeepValidationError(field) {
      return !!_.get(this.serverError, `graphQLErrors[0].extensions.validation['data.${field}']`)
    },
    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validate() {
      this.errors = {}
      this.errors.email = (!this.newUser.email || !this.validateEmail(this.newUser.email))
      this.errors.passwordLength = this.newUser.password.length < 8
      this.errors.passwordRepeat = this.newUser.password !== this.newUser.passwordRepeat
      this.errors.privacy = !this.acceptPrivacy

      for (let i in this.errors) {
        if (this.errors[i] === true) {
          return false
        }
      }
      return true
    },
    registerNew() {
      if (!this.validate()) return
      //if (!this.validateNew()) return
      this.isLoading = true
      this.$apollo.mutate({
        mutation: REGISTER_NEW_MEMBER_MUTATION,
        variables: {
          data: {
            email: this.newUser.email,
            password: this.newUser.password,
            ...this.newMember,
            billingAddress: this.hasInvoiceAddress ? this.newMember.billingAddress : null,
          }
        }
      }).then(({data}) => {
        this.isLoading = false
        if (!data.EaRegisterNew) {
          this.errorMessage = 'Leider ist ein Fehler aufgetreten.'
        } else {
          this.successMessage = true
        }
      }).catch(error => {
        this.isLoading = false
        this.serverError = error
        this.errorMessage = 'Leider ist ein Fehler aufgetreten.'
      })
    },
    register() {
      if (!this.validate()) return
      this.isLoading = true
      this.$apollo.mutate({
        mutation: REGISTER_EXISTING_MEMBER_MUTATION,
        variables: {
          data: {
            email: this.newUser.email,
            password: this.newUser.password
          }
        }
      }).then(() => {
        this.isLoading = false
        this.successMessage = true
      }).catch(error => {
        this.isLoading = false
        this.serverError = error
        if (this.hasValidationError('validation.exists') && this.$config.SHOW_REGISTER_NEW) {
          this.serverError = null
          this.isNewMember = true
          console.log('lolololo')
          return;
        }
        this.errorMessage = 'Leider ist ein Fehler aufgetreten.'
      })
    }
  },
  apollo: {
    EaAssociationFunctions: {
      query: GET_ASSOCIATION_FUNCTIONS_QUERY
    }
  }
}
</script>

<style scoped>

</style>