<template>
  <auth-base>
    <h1>Passwort zurücksetzen</h1>
    <div class="has-text-left">
      <template v-if="!SuccessMessage">
        <b-message has-icon type="is-danger" v-if="!!ErrorMessage"><b>Oh, nein.</b><br/>{{ErrorMessage}}</b-message>
        <b-field label="E-Mail Adresse">
          <b-input @keyup.enter="resetPassword" placeholder="Ihre E-Mail Adresse" size="is-medium" type="email"
                   v-model="email"/>
        </b-field>

        <b-field label="Neues Passwort">
          <b-input @keyup.enter="resetPassword" placeholder="Neues Passwort" size="is-medium" type="password"
                   v-model="password"/>
        </b-field>
        <b-field label="Neues Passwort wiederholen">
          <b-input @keyup.enter="resetPassword" placeholder="Neues Passwort wiederholen" size="is-medium"
                   type="password"
                   v-model="passwordConfirmation"/>
        </b-field>
        <a :class="{'is-loading' : isLoading}" @click="resetPassword"
           class="button is-block is-primary is-medium m-t-lg">Passwort ändern</a>

      </template>
      <template v-else>
        <b-message type="is-success"><b>Passwort geändert.</b><br/>Ihr Passwort wurde erfolgreich geändert. Sie können
          sich nun mit Ihrem neuen Passwort einloggen.
          <router-link :to="{name: 'Login'}" class="button m-t-md">Zum Login</router-link>
        </b-message>
      </template>
    </div>

  </auth-base>
</template>

<script>
  import AuthBase from './AuthBase'
  import RESET_PASSWORD_MUTATION from '../../graphql/EaResetPassword.gql'

  export default {
    components: {AuthBase},
    data() {
      return {
        password: '',
        passwordConfirmation: '',
        email: '',
        ErrorMessage: '',
        SuccessMessage: '',
        isLoading: false
      }
    },
    name: 'password-reset',
    props: ['token'],
    methods: {
      resetPassword() {
        this.isLoading = true
        this.$apollo.mutate({
          mutation: RESET_PASSWORD_MUTATION,
          variables: {
            data: {
              email: this.email,
              password: this.password,
              token: this.token
            }
          }
        }).then(() => {
          this.isLoading = false
          this.SuccessMessage = 'Password erfolgreich geändert'
        }).catch(() => {
          this.isLoading = false
          this.ErrorMessage = 'Leider ist ein Fehler ist aufgetreten'
        })
      }
    }
  }
</script>

<style scoped>

</style>