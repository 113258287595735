<template>
    <div>
        <b-field label="Altes Passwort"
                 :type="errors.oldWrong ? 'is-danger' : ''"
                 :message="errors.oldWrong ? 'Das alte Passwort scheint nicht zu stimmen' : ''">
            <b-input v-model="oldPassword"
                     placeholder="Altes Passwort eingeben"
                     type="password"/>
        </b-field>
        <b-field label="Neues Passwort"
                 :type="errors.minLength ? 'is-danger' : ''"
                 message="das Passwort muss mindestens 8 Zeichen lang sein.">
          <b-input v-model="newPassword"
                   placeholder="Neues Passwort eingeben"
                   type="password"
                   @keyup.enter="change"/>
        </b-field>
        <b-field label="Neues Passwort wiederhohlen"
                 :type="errors.notTheSame ? 'is-danger' : ''"
                 :message="errors.notTheSame ? 'Sie haben hier leider nicht das gleiche Passwort eingegeben.' : ''">
            <b-input v-model="repeatPassword"
                     type="password"
                     placeholder="Neues Passwort wiederhohlen"
                     @keyup.enter="change"/>
        </b-field>
        <button class="button m-t-md" :class="{'is-loading':isLoading}" @click="change">Passwort ändern</button>
    </div>
</template>

<script>
import UPDATE_PROFILE_MUTATION from '../graphql/updateEaProfile.gql'

export default {
  name: 'password-changer',
  data () {
    return {
      isLoading: false,
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      errors: {}
    }
  },
  methods: {
    change () {
      if (this.check()) {
        this.isLoading = true
        this.errors.oldWrong = false
        this.$apollo.mutate({
          mutation: UPDATE_PROFILE_MUTATION,
          variables: {
            profile: {
              oldPassword: this.oldPassword,
              newPassword: this.newPassword
            }
          }
        }).then(() => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: 'Passwort erfolgreich geändert!',
            type: 'is-success'
          })
          this.oldPassword = ''
          this.newPassword = ''
          this.repeatPassword = ''
        }).catch((error) => {
          this.isLoading = false
          if (!error.networkError) {
            this.errors.oldWrong = true
          }
        })
      }
    },
    check () {
      this.errors = {}
      let hasError = false
      if (this.newPassword.length < 6) {
        hasError = true
        this.errors.minLength = true
      }
      if (this.newPassword !== this.repeatPassword) {
        hasError = true
        this.errors.notTheSame = true
      }
      return !hasError
    }
  }
}
</script>

<style scoped>

</style>