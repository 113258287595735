export const LECTURESHIP_LEVELS = {
  LECTURER: {
    name: 'SeminarleiterIn'
  },
  TRAINEE: {
    name: 'HospitantIn'
  },
  MENTOR: {
    name: 'MentorIn'
  }
}

export const SEMINAR_STATUS = [
  {
    key: 'CANCELED',
    name: 'Abgesagt',
    class: 'is-grey-light',
    message: ''
  },
  {
    key: 'PLANNING',
    name: 'In Planung',
    class: 'is-warning-light',
    message: 'Das Seminar wird noch nicht auf ehrenamtsakademie.de angezeigt.'
  },
  {
    key: 'PUBLIC',
    name: 'Veröffentlicht',
    class: 'is-success-light',
    message: 'Das Seminar ist auf ehrenamtsakademie.de sichtbar.'
  },
  {
    key: 'INVITED',
    name: 'Eingeladen',
    class: 'is-danger-light',
    message: 'Alle Teilnehmer wurden per E-Mail/Brief eingeladen'
  },
  {
    key: 'COMPLETED',
    name: 'Durchgeführt',
    class: 'is-info-light',
    message: 'Unterschriftenliste erhalten und nicht erschienene Teilnahmen bearbeitet.<br/>' +
      'E-Mail Fragebogen versendet.'
  },
  {
    key: 'CLEARED',
    name: 'Abgerechnet',
    class: '',
    message: 'Seminarleiter Honorar, Reisekosten, Raum- Verpflegung abgerechnet'
  }
]
