<template>

    <div class="columns is-multiline">
        <div class="column is-full">
            <strong>Ausbildungsübersicht</strong>
            <table class="transtable">
                <tr v-for="one in member.computed.requirements">
                    <td :class="{'optional' : !one.isRequired}"> {{one.template.name}} <span
                            v-if="!one.isRequired">(optional)</span></td>
                    <td>
                        <b-icon icon="check" type="is-success" v-if="one.state === 3"/>
                        <b-icon icon="close" type="is-danger" v-if="one.state === 1"/>
                        <b-icon icon="timer-sand" type="is-warning" v-if="one.state === 2"/>
                    </td>
                </tr>
            </table>
            <div v-if="member.computed.requirements.length < 1" class="has-text-grey">
                keine Seminare notwendig
            </div>
        </div>
        <div class="column is-full">
            <strong>Funktionen</strong>
            <div v-for="func in member.functions">
                {{func.function_name}}
            </div>
            <div v-if="member.functions.length < 1" class="has-text-grey">
                keine
            </div>
        </div>
        <div class="column is-full">
            <strong>Aktuelle Seminare</strong>
            <table class="transtable">
                <tr v-for="one in member.computed.currentParticipations">
                    <td>{{one.seminar.template.name}}</td>
                    <td>{{one.seminar.end | moment('DD.MM.YY')}}</td>


                    <td>
                        <b-tag v-if="one.status === 'PARTICIPATING'">Teilnahmeliste</b-tag>
                        <b-tag v-if="one.status === 'WAITING'">Warteliste</b-tag>
                    </td>
                </tr>
            </table>
            <div v-if="member.computed.currentParticipations.length < 1" class="has-text-grey">
                keine
            </div>
        </div>
        <div class="column is-full">
            <strong>Abgeschlossene Seminare</strong>
            <table class="transtable">
                <tr v-for="one in member.computed.completed">
                    <td>
                        {{one.name}}
                    </td>
                    <td> {{one.date | moment('DD.MM.YY')}}
                    </td>
                </tr>
                <div v-if="member.computed.completed.length < 1" class="has-text-grey">
                    keine
                </div>
            </table>
        </div>
        <div class="column is-full">
            <strong>Datenschutzerklärung</strong>
            <div>
              <template v-if="member.privacy_at">am {{
                  member.privacy_at | moment('DD.MM.YY')
                }} akzeptiert
              </template>
              <template v-else>nicht akzeptiert</template>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "MemberTableDetail",
  props: ['member'],
}
</script>

<style scoped>
.optional {
    opacity: 0.6;
}

</style>