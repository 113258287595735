<template>
  <div v-if="!isLoading && EaSeminarPage !== null">
    <h1>Infoseite - {{ EaSeminarPage.name }}</h1>
    <div class="card">
      <div class="card-content is-relative page">
        <h2>Infos</h2>
        <div v-if="!$apollo.queries.EaSeminarPage.loading" class="content">
          <div v-html="$options.filters.marked(EaSeminarPage.content)" v-if="EaSeminarPage.content !== null"></div>
          <div v-else>Hier steht leider noch nichts, probiere es doch später nochmal.</div>
        </div>
        <seminar-appointments :appointments="EaSeminarPage.appointments"/>
      </div>
    </div>
  </div>
  <div v-else-if="EaSeminarPage === null">
    <div class="mt-6 has-text-centered">
      <h1>Leider ist hier nichts :(</h1>
    </div>
  </div>
</template>

<script>
import GET_EA_SEMINAR_PAGE_QUERY from '../graphql/EaSeminarPage.gql'
import Appointment from "../components/Appointment";
import SeminarSignupButton from "../components/SeminarSignupButton";
import SeminarAppointments from "@/components/SeminarAppointments";

export default {
  name: "SeminarDetail",
  components: {SeminarAppointments, SeminarSignupButton, Appointment},
  props: ['id'],
  data() {
    return {
      EaSeminarPage: {},
      isLoading: 0
    }
  },
  apollo: {
    EaSeminarPage: {
      query: GET_EA_SEMINAR_PAGE_QUERY,
      loadingKey: 'isLoading',
      variables() {
        return {
          id: this.id
        }
      }
    }
  }
}
</script>

<style>
</style>