<template>
    <div>
        <vue-treeselect :clearable="true" :defaultExpandLevel="1" :multiple="multiple" :options="DepartmentTree"
                        :placeholder="multiple ? 'Verbandsstufen auswählen' : 'Verbandsstufe auswählen'"
                        :value="value"
                        @input="(value) => {this.$emit('input', value)}">
        </vue-treeselect>
        <!--<b-autocomplete
                :data="filteredDepartments"
                :open-on-focus="true"
                placeholder="Verbandsstufe suchen"
                field="nameLong"
                @input="input => this.query = input"
        >

        </b-autocomplete>-->
    </div>
</template>

<script>
// import the component
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import GET_DEPARTMENTS_QUERY from '../graphql/EaAssociationDepartments.gql'
import VueTreeselect from '@riophae/vue-treeselect'

const nest = (items, id = null, link = 'parent_id') =>
  items
    .filter(item => item[link] === id)
    //.map(item => ({...item, children: nest(items, item.id)}));
    .map(item => {
      let children = nest(items, item.id);
      return children.length > 0 ? {...item, children: children} : item;
    });

export default {
  name: "DepartmentSelect",
  components: {VueTreeselect},
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    value: [String, Array]
  }
  ,
  data() {
    return {
      EaAssociationDepartments: [],
      query: '',
      bla: null
    }
  },
  methods: {},
  computed: {
    DepartmentTree() {
      let list = this.EaAssociationDepartments.map((node) => ({...node, label: node.name}));
      return nest(list)
    }
  },
  apollo: {
    EaAssociationDepartments: {
      query: GET_DEPARTMENTS_QUERY
    }
  }
}
</script>

<style scoped>

</style>