<template>
  <div class="participations">
    <h3>Meine Seminare</h3>
    <b-table :data="future"
             striped
             :loading="!!isLoading"
             bordered
             :mobile-cards="false"
             :default-sort="['seminar.date', 'asc']"
             sortable>
      <b-table-column v-slot="props" label="Datum" field="seminar.date" sortable width="13%">
        {{ props.row.seminar.date | moment('DD.MM.YYYY') }}
      </b-table-column>
      <b-table-column v-slot="props" label="Name" width="72%">
        <router-link :to="{name: 'Seminar', params: {id: props.row.seminar.id}}">
          {{ props.row.seminar.template.name }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" label="Status" width="15%">
        {{ props.row.status === "PARTICIPATING" ? 'Teilnehmer' : 'Warteliste' }}
      </b-table-column>
      <div slot="empty" class="card-content has-text-centered" v-if="!isLoading">
        Aktuell haben Sie keine ausstehenden Seminare.<br/>
        <router-link :to="{name: 'Seminars'}">Alle Seminare</router-link>
      </div>
    </b-table>
    <h3>Abgeschlosse Seminare</h3>
    <b-table :data="completed"
             sortable
             :default-sort="['date', 'DESC']"
             striped
             :mobile-cards="false"
             :loading="!!isLoading"
             paginated
             bordered>
      <b-table-column v-slot="props" label="Datum" field="date" sortable width="13%">
        {{ props.row.date | moment('DD.MM.YYYY') }}
      </b-table-column>
      <b-table-column v-slot="props" label="Name" field="name" sortable width="87%">
        {{ props.row.name }}
      </b-table-column>
      <div slot="empty" class="card-content has-text-centered" v-if="!isLoading">
        Sie haben noch keine Seminare besucht.
      </div>
      <b-table-column v-slot="props">
        <a :href="`${$config.API_EXPORT_URL}/certificate/${props.row.type}/${props.row.id}`" target="_blank">
          <b-icon icon="file-check-outline"/>
        </a>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import GET_MY_EDUCATION_QUERY from '../graphql/EaMyEducation.gql'

export default {
  name: "MySeminars",
  data() {
    return {
      EaMyParticipations: [],
      EaMyCertificates: [],
      isLoading: 0
    }
  },
  apollo: {
    EaMyParticipations: {
      query: GET_MY_EDUCATION_QUERY,
      loadingKey: 'isLoading'
    },
    EaMyCertificates: {
      query: GET_MY_EDUCATION_QUERY,
      loadingKey: 'isLoading'
    }
  },
  computed: {
    completed() {
      return this.EaMyParticipations.filter((p) => {
        return p.status === 'PARTICIPATING' && (p.seminar.status === 'CLEARED' || p.seminar.status === 'COMPLETED')
      }).map(p => ({
        type: 'P',
        id: p.id,
        name: p.seminar.template.name,
        date: p.seminar.end
      })).concat(this.EaMyCertificates.map(c => ({type: 'C', id: c.id, name: c.template.name, date: c.created_at})))
    },
    future() {
      return this.EaMyParticipations.filter((p) => {
        return p.status !== 'CANCELED' && (p.seminar.status === 'PUBLIC' || p.seminar.status === 'INVITED')
      })
    }
  }
}
</script>

<style scoped>
.participation {
  background: whitesmoke;
  margin: 0.5rem 0;
  padding: 0.5rem;
  text-align: left;
}

.participations {

}
</style>