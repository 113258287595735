<template>
    <button class="button is-danger is-outlined" @click="deleteAccount" :class="{'is-loading': isLoading}">
        Account löschen
    </button>
</template>

<script>
import DELETE_PROFILE_MUTATION from '../graphql/deleteEaProfile.gql'
export default {
  name: 'delete-profile-button',
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    deleteAccount () {
      this.$buefy.dialog.prompt({
        title: 'Account löschen',
        type: 'is-danger',
        //hasIcon: true,
        confirmText: 'Account löschen',
        message: `Um Ihren Account <b>unwiederruflich</b> zu löschen müssen Sie noch Ihr Passwort eingeben.<br/> Möchtest Sie Ihren Account wirklich löschen?`,
        inputAttrs: {
          placeholder: 'Passwort eingeben',
          type: 'password'
        },
        onConfirm: (password) => {
          this.isLoading = true
          this.$apollo.mutate({
            mutation: DELETE_PROFILE_MUTATION,
            variables: {
              password: password
            }
          }).then(() => {
            this.$store.dispatch('logout')
          }).catch(() => {
            this.isLoading = false
            this.$buefy.toast.open({
              message: 'Ein Fehler ist aufgetreten!',
              type: 'is-danger'
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>