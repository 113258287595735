<template>
  <div class="home">
    <my-next-seminar/>
    <MarkdownSetting id="eaMemberstartpage" class="box"/>
    <div v-if="$config.SHOW_MY_EDUCATION_STATUS || $config.SHOW_MY_FUNCTIONS" class="columns">
      <div v-if="$config.SHOW_MY_FUNCTIONS" class="column">
        <my-functions/>
      </div>
      <div v-if="$config.SHOW_MY_EDUCATION_STATUS" class="column">
        <my-education-status/>
      </div>
    </div>
    <my-seminars/>
  </div>
</template>

<script>
import MySeminars from "../components/MySeminars";
import MyFunctions from "../components/MyFunctions";
import MarkdownSetting from "../components/MarkdownSetting";
import MyNextSeminar from "../components/MyNextSeminar";
import MyEducationStatus from "../components/MyEducationStatus";

import SELF_CERTIFICATION_MUTATION from "@/graphql/EaSelfCertification.gql"

export default {
  name: 'dashboard',
  components: {MyEducationStatus, MyNextSeminar, MarkdownSetting, MyFunctions, MySeminars},
  beforeCreate() {
    let tokens = JSON.parse(localStorage.getItem("selfCertificationTokens"));
    if (Array.isArray(tokens)) {
      tokens.forEach(token => {
        this.$apollo.mutate({
          mutation: SELF_CERTIFICATION_MUTATION,
          variables: {token},
        }).then(({data}) => {
          this.$buefy.notification.open({
            message: `<h2>Herzlichen Glückwunsch!</h2> Sie haben <b>${data.EaSelfCertification.template.name}</b> erfolgreich abgeschlossen`,
            type: "is-success",
            indefinite: true
          })
        }).catch(() => {
          this.$buefy.notification.open({
            message: `<h2>Fehler!</h2> Leider ist beim Verbuchen Ihrer Teilnahmebestätigung ein Fehler aufgetreten`,
            type: "is-danger",
            indefinite: true
          })
        })
        localStorage.removeItem("selfCertificationTokens")
      })
    }
  }
}
</script>
