<template>
  <div :class="{'price' : !small}">
    <slot v-if="price !== null"/>
    <template v-if="price === null"></template>
    <template v-else-if="price === 0">kostenfrei</template>
    <template v-else>{{ price }} €</template>
  </div>
</template>

<script>
export default {
  name: "SeminarPrice",
  props: {
    price: Number,
    small: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>

.price {
  font-size: 1.25em;
  white-space: nowrap;
}

</style>