<template>
  <div>
    <MarkdownSetting class="box" id="eaAnalysispage"/>
    <h1>Auswertung</h1>

    <member-filter v-model="filters"/>
    <b-table
        :currentPage="EaMembers.paginatorInfo.currentPage || 0"
        :data="computedMembers"
        :default-sort="[this.sort.field, this.sort.order]"
        :loading="$apollo.loading"
        :per-page="EaMembers.paginatorInfo.perPage || 15"
        :total="EaMembers.paginatorInfo.total || 0"
        @page-change="(page) => {this.page = page}"
        @sort="(field, order) => {this.sort = {field,order: order.toUpperCase()};}"
        backend-pagination
        backend-sorting
        class="table-has-frame"
        detailed
        narrowed
        paginated
        striped
        :mobile-cards="false"
    >
      <b-table-column v-slot="props" field="fullName" label="Name" sortable width="100%">
        {{ props.row.fullName }}
      </b-table-column>
      <b-table-column v-slot="props" field="mitgliedernummer" label="Mitgliedernummer" numeric sortable>
        {{ props.row.mitgliedernummer }}
      </b-table-column>
      <b-table-column v-slot="props" label="Notwendige Ausbildung" numeric>
        <b-tag class="ampel" style="opacity:0.6;" v-if="props.row.computed.ampel === 0">Keine Funktion
        </b-tag>
        <b-tag class="ampel" type="is-success" v-if="props.row.computed.ampel === 3">Vollständig
        </b-tag>
        <b-tag class="ampel" type="is-warning" v-if="props.row.computed.ampel === 2">Ausstehend
        </b-tag>
        <b-tag class="ampel" type="is-danger" v-if="props.row.computed.ampel === 1">Unvollständig
        </b-tag>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <member-table-detail :member="props.row"/>
      </template>
      <template slot="empty">
        <div class="has-text-centered" style="padding:2rem;">Keine Mitglieder gefunden.</div>
      </template>
      <template slot="bottom-left" v-if="EaMembers.data">
        Zeige {{ EaMembers.paginatorInfo.firstItem }} bis {{ EaMembers.paginatorInfo.lastItem }} Einträge von {{
          EaMembers.paginatorInfo.total
        }}
      </template>
    </b-table>
  </div>
</template>

<script>
import GET_MEMBERS_QUERY from '../graphql/EaMembers.gql'
import MemberTableDetail from "../components/MemberTableDetail";
import utils from '../utils'
import MemberFilter from "../components/MemberFilter";
import MarkdownSetting from "../components/MarkdownSetting";
import BTabItem from "buefy/src/components/tabs/TabItem";

export default {
  name: "Analysis",
  components: {BTabItem, MarkdownSetting, MemberFilter, MemberTableDetail},
  data() {
    return {
      EaMembers: {
        items: [],
        paginatorInfo: {}
      },
      page: 1,
      filters: {},
      sort: {
        field: 'mitgliedernummer',
        order: 'ASC'
      }
    }
  },
  computed: {
    computedMembers() {
      return this.EaMembers.data ? this.EaMembers.data.map(utils.member.memberAddComputed) : []
    }
  },
  apollo: {
    EaMembers: {
      query: GET_MEMBERS_QUERY,
      variables() {
        return {
          page: this.page,
          filter: this.filters,
          sort: this.sort,
        }
      }
    }
  }
}

</script>

<style scoped>
.ampel {
  width: 6rem;
}
</style>