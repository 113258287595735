<template>
    <div>
        <div class="column">
            <div class="card">
                <div class="card-content">
                    <p>Beginn: {{appointment.begin | moment('DD.MM.YYYY HH:mm')}}<br/>
                        Ende: {{appointment.end | moment('DD.MM.YYYY HH:mm')}}
                    </p><br/>
                    <p>
                        <template v-if="!appointment.place">Noch kein Ort angegeben</template>
                        <template v-else>
                            {{appointment.place.name}}<br/>
                            {{appointment.place.street}}<br/>
                            {{appointment.place.zip}} {{appointment.place.city}}
                        </template>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Appointment",
  props: ['appointment']
}
</script>

<style scoped>

</style>