import Vue from 'vue'
import store from './store/index'
import Router from 'vue-router'

import Home from './views/Home.vue'
import Dashboard from './views/Dashboard'
import Seminars from './views/Seminars.vue'
import SeminarDetail from './views/SeminarDetail'
import Lectureships from './views/Lectureships'
import Analysis from './views/Analysis'
import UserSettings from './views/UserSettings'

import Login from './views/auth/Login'
import Register from './views/auth/Register'
import SendPasswordReset from './views/auth/SendPasswordReset'
import PasswordReset from './views/auth/PasswordReset'
import Activate from './views/auth/Activate'
import Support from './views/Support'
import SeminarPage from "@/views/SeminarPage";
import NotFound from "@/views/NotFound";
import Contact from "@/views/Contact"

import {SHOW_PUBLIC_SEMINAR_LIST} from "@/config";

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {redirectAuthenticated: true}
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      props: true,
      meta: {redirectAuthenticated: true}
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      props: true,
      meta: {redirectAuthenticated: true}
    },
    {
      path: '/activate/:token',
      name: 'Activate',
      props: true,
      component: Activate,
      meta: {redirectAuthenticated: true}
    },
    {
      path: '/password/email',
      name: 'SendPasswordReset',
      component: SendPasswordReset,
      props: true,
      meta: {redirectAuthenticated: true}
    },
    {
      path: '/password/reset/:token',
      name: 'PasswordReset',
      props: true,
      component: PasswordReset,
      meta: {redirectAuthenticated: true}
    },
    {
      path: '/support',
      name: 'Support',
      props: true,
      component: Support
    },
    {
      path: '/contact',
      name: 'Kontakt',
      props: true,
      component: Contact
    },
    {
      path: '/dashboard',
      name: 'Overview',
      component: Dashboard,
      meta: {requiresAuth: () => true}
    },
    {
      path: '/usersettings',
      name: 'Usersettings',
      component: UserSettings,
      meta: {requiresAuth: () => true}
    },
    {
      path: '/seminars',
      name: 'Seminars',
      component: Seminars,
      meta: {requiresAuth: () => !SHOW_PUBLIC_SEMINAR_LIST}
    },
    {
      path: '/lectureships',
      name: 'Seminarleitertätigkeit',
      component: Lectureships,
      meta: {requiresAuth: () => true}
    },
    {
      path: '/analysis',
      name: 'Analysis',
      component: Analysis,
      meta: {requiresAuth: () => true}
    },
    {
      path: '/seminars/:id',
      name: 'Seminar',
      component: SeminarDetail,
      props: true,
    },
    {
      path: '/page/:id',
      name: 'Page',
      component: SeminarPage,
      props: true,
    },
    {
      path: '/selfcert/:token',
      //component: SelfCertification,
      props: true,
      redirect: to => {
        let tokens = JSON.parse(localStorage.getItem("selfCertificationTokens"));
        if (!Array.isArray(tokens)) tokens = []
        tokens.push(to.params.token)
        localStorage.setItem('selfCertificationTokens', JSON.stringify(tokens.filter((v, i, a) => a.indexOf(v) === i)))
        if (!store.getters.loggedIn) {
          return {
            name: "Login",
            params: {
              message: "<h1>Teilnahmebestätigung</h1><p>Herzlichen Glückwunsch zur erfolgreichen Teilnahme!<br/>Damit Ihre Teilnahmebestätigung in Ihrem Account verbucht wird loggen Sie sich bitte ein oder registrieren Sie sich.</p>"
            }
          }
        } else {
          return "/dashboard"
        }
      }
    },
    {
      path: '*',
      name: "Not Found",
      component: NotFound
    }
  ],
  mode: 'history',
  linkActiveClass: 'is-active'
})

router.beforeEach((to, from, next) => {
  let loggedIn = store.getters.loggedIn;
  // if required but not loggedIn
  if (to.matched.some(record => !!record.meta.requiresAuth && record.meta.requiresAuth() && !loggedIn)) {
    const loginPath = window.location.pathname;
    next({
      path: '/login',
      replace: true,
      query: {from: loginPath}
    })
    // if !required but loggedIn
  } else if (to.matched.some(record => record.meta.redirectAuthenticated) && loggedIn) {
    next({
      path: '/dashboard',
      replace: true
    })
  }
  next()
})

export default router