import Vue from 'vue'
import {apolloProvider} from './main'
import GET_SYSTEM_SETTINGS_QUERY from './graphql/SystemSettings.gql'

const apibase = process.env.VUE_APP_EAAM_API_URL || '/'
export const API_GRAPHQL_ENDPOINT = apibase + 'graphql'
export const API_INIT_CSRF = apibase + 'csrf-cookie'
export const API_DOWNLOAD_URL = apibase + 'eaapi/download'
export const API_EXPORT_URL = apibase + 'eaapi/export'
export let SHOW_MY_EDUCATION_STATUS = false
export let SHOW_MY_FUNCTIONS = false
export let SHOW_ANALYZE = false
export let SHOW_PUBLIC_SEMINAR_LIST = false
export let SHOW_EDITABLE_ADDRESSES = false
export let SHOW_EDITABLE_ACCESSIBILITY = false
export let SHOW_EDITABLE_ACCOMMODATION = false
export let SHOW_BILLING_ADDRESSES = false
export let SHOW_DISTRICTS = false
export let SHOW_ASSOCIATION_LEVELS = false
export let SHOW_SEMINAR_REGISTER = false
export let SHOW_REGISTER = false
export let SHOW_REGISTER_NEW = false
export let SHOW_PRICE = false
export const LOGO = process.env.VUE_APP_EAAM_LOGO ? require(process.env.VUE_APP_EAAM_LOGO) : require('./assets/logo_ehrenamtsakademie.svg');
export let SECOND_EAAM_LINK_TEXT = null
export let SECOND_EAAM_LINK = null
export let SHOW_SUPPORT_FORM = false

export let SHOW_CONTACT_FORM = false
export let DEFAULT_LANG = process.env.VUE_APP_EAAM_LANG || 'de_vdk'
export const FALLBACK_LANG = 'de_vdk'

export const accessibilityTags = {
  '\u267F': 'Rollstuhlfahrer/in',
  'Gehbehinderung': 'Gehbehinderung',
  '\u{1F171}\u{FE0F}': 'Merkzeichen "B"',
  '\u{1F436}': 'Begleithund',
  '\u{1F9BB} ': 'Hörschädigung',
  '\u{1F469}\u{200D}\u{1F9AF}': 'Sehbehinderung'
}

export const accommodationTags = {
  'Vege': 'Vegetarier',
  'Vega': 'Veganer',
  'Lac': 'Laktoseintoleranz',
  'Al': 'Allergisch'
}

export const loadSettings = async () => {
  const {data: {SystemSettings}} = await apolloProvider.defaultClient.query({query: GET_SYSTEM_SETTINGS_QUERY})
  SHOW_ASSOCIATION_LEVELS = SystemSettings.departmentHierarchy
  SHOW_MY_EDUCATION_STATUS = SystemSettings.myEducationStatus
  SHOW_MY_FUNCTIONS = SystemSettings.myFunctions
  SHOW_DISTRICTS = SystemSettings.districts
  SHOW_ANALYZE = SystemSettings.analytics
  SHOW_SEMINAR_REGISTER = SystemSettings.seminarSignup
  SHOW_REGISTER = SystemSettings.registration
  SHOW_REGISTER_NEW = SystemSettings.registration && SystemSettings.registrationOfNewMembers
  SHOW_PRICE = SystemSettings.prices
  SECOND_EAAM_LINK_TEXT = SystemSettings.secondPageName
  SECOND_EAAM_LINK = SystemSettings.secondPage
  SHOW_SUPPORT_FORM = SystemSettings.supportForm
  SHOW_CONTACT_FORM = SystemSettings.contactForm
  SHOW_PUBLIC_SEMINAR_LIST = SystemSettings.publicSeminarList
  SHOW_EDITABLE_ADDRESSES = SystemSettings.userEditableAddresses
  SHOW_EDITABLE_ACCESSIBILITY = SystemSettings.userEditableAccommodation
  SHOW_EDITABLE_ACCOMMODATION = SystemSettings.userEditableAccessibility
  SHOW_BILLING_ADDRESSES = SystemSettings.memberBillingAddresses

  Vue.prototype.$config = {
    SHOW_ASSOCIATION_LEVELS,
    SHOW_MY_EDUCATION_STATUS,
    SHOW_MY_FUNCTIONS,
    SHOW_ANALYZE,
    SHOW_DISTRICTS,
    SHOW_SEMINAR_REGISTER,
    SHOW_REGISTER_NEW,
    SHOW_REGISTER,
    SHOW_PUBLIC_SEMINAR_LIST,
    SHOW_EDITABLE_ADDRESSES,
    SHOW_EDITABLE_ACCESSIBILITY,
    SHOW_EDITABLE_ACCOMMODATION,
    SHOW_BILLING_ADDRESSES,
    SHOW_PRICE,
    LOGO,
    SECOND_EAAM_LINK_TEXT,
    SECOND_EAAM_LINK,
    SHOW_SUPPORT_FORM,
    SHOW_CONTACT_FORM,
    API_EXPORT_URL,
    API_DOWNLOAD_URL,
    accommodationTags,
    accessibilityTags
  }

}