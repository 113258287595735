<template>
  <section class="memberfilter mb-3">
    <div class="columns is-multiline is-mobile is-variable is-1">
      <div class="column is-full-mobile">
        <b-input v-model="fields.mitgliedernummer" placeholder="Mitgliedernummer"></b-input>
      </div>
      <div class="column is-half-mobile">
        <b-input v-model="fields.firstName" placeholder="Vorname eingeben"></b-input>
      </div>
      <div class="column is-half-mobile">
        <b-input v-model="fields.lastName" placeholder="Nachname eingeben"></b-input>
      </div>
      <div class="column is-narrow is-half-mobile">
        <div class="button is-flex-mobile" @click="reset">
          <b-icon icon="filter-remove" size="is-small"/>
          <span>Zurücksetzen</span></div>
      </div>
      <div class="column is-narrow is-half-mobile">
        <div @click="filterOpen = filterOpen !== true" class="button is-flex-mobile">
          <span>Weitere Filter</span>
          <b-icon v-if="!filterOpen" icon="menu-down"></b-icon>
          <b-icon v-else icon="menu-up"></b-icon>
        </div>
      </div>
    </div>
    <div class="m-b-md" v-if="filterOpen">
      <div class="columns">
        <div class="column">
          <b-field label="Hat Seminare abgeschlossen" expanded>
            <b-taginput
                maxtags="4"
                v-model="fields.hasParticipations"
                :data="filteredHasParticipations"
                autocomplete
                :allowNew="false"
                :open-on-focus="true"
                field="name"
                icon="label"
                placeholder="Hat Seminare abgeschlossen..."
                @typing="input => this.hasParticipationsFieldValue = input"
            ></b-taginput>
          </b-field>
          <b-field label="Hat Seminare nicht abgeschlossen" expanded>
            <b-taginput
                maxtags="4"
                v-model="fields.doesntHaveParticipations"
                :data="filteredDoesntHaveParticipations"
                autocomplete
                :allowNew="false"
                :open-on-focus="true"
                field="name"
                icon="label"
                placeholder="Hat Seminare nicht abgeschlossen..."
                @typing="input => this.doesntHaveParticipationsFieldValue = input"
            ></b-taginput>
          </b-field>
          <b-field label="Ist Mitglied in Verbandsstufe">
            <department-select multiple v-model="fields.isMemberOfDepartments"/>
          </b-field>

          <b-field label="Steht aktuell auf Teilnahme/Warteliste">
            <b-select v-model="fields.hasCurrentParticipations">
              <option :value="null">Egal</option>
              <option :value="true">Ja</option>
              <option :value="false">Nein</option>
            </b-select>
          </b-field>
          <b-field label="Datenschutz">
            <b-select v-model="fields.agreedToPrivacy">
              <option :value="null">Egal</option>
              <option :value="true">Akzeptiert</option>
              <option :value="false">Nicht akzeptiert</option>
            </b-select>
          </b-field>

        </div>
        <div class="column">
          <b-field label="Benötigt Seminare für Ausbildungspaket">
            <b-field>
              <p class="control is-expanded">
                <b-taginput
                    style="z-index:6;"
                    expanded
                    maxtags="4"
                    v-model="fields.needsTemplates"
                    :data="filteredNeedsTemplates"
                    autocomplete
                    :allowNew="false"
                    :open-on-focus="true"
                    field="name"
                    icon="label"
                    placeholder="Benötigt Seminare für Ausbildungspaket..."
                    @typing="input => this.needsTemplatesFieldValue = input"
                ></b-taginput>
              </p>
              <p class="control" style="z-index:5;">
                <b-select v-model="fields.needsTemplatesIsRequired">
                  <option :value="null">Beides</option>
                  <option :value="true">Notwendig</option>
                  <option :value="false" class="lowOpa">Optional</option>
                </b-select>
              </p>
            </b-field>
          </b-field>
          <b-field class="m-b-lg" grouped>
            <b-field label="Hat Funktionen">
              <b-select v-model="fields.hasAnyFunction">
                <option :value="null">Egal</option>
                <option :value="true">Ja</option>
                <option :value="false">Nein</option>
              </b-select>
            </b-field>
          </b-field>
          <b-field expanded label="Hat Funktionen (spezifisch)">
            <template v-if="!!EaAssociationFunctions">
              <b-taginput
                  maxtags="4"
                  v-model="fields.hasAssociationFunctions"
                  :data="filteredHasFunctions"
                  autocomplete
                  :allowNew="false"
                  :open-on-focus="true"
                  field="name"
                  icon="label"
                  placeholder="Mitglied hat Funktionen..."
                  @typing="input => this.hasAssociationFunctionsFieldValue = input">
              </b-taginput>
            </template>
          </b-field>
          <b-field label="Hat Funktion in Verbandsstufe">
            <department-select v-model="fields.hasFunctionInDepartment"/>
          </b-field>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GET_TEMPLATES_QUERY from '../graphql/EaTemplates.gql'
import GET_ASSOCIATION_FUNCTIONS_QUERY from '../graphql/EaAssociationFunctions.gql'
import {filterObjects} from '../utils'
import DepartmentSelect from "./DepartmentSelect";

let filterDefaults = {
  hasAssociationFunctions: [],
  hasParticipations: [],
  doesntHaveParticipations: [],
  needsTemplates: [],
  firstName: '',
  lastName: '',
  mitgliedernummer: '',
  hasCurrentParticipations: null,
  hasFunctionInDepartment: undefined,
  isMemberOfDepartments: [],
  agreedToPrivacy: null,
  hasAnyFunction: null
};

export default {
  name: 'member-filter',
  components: {DepartmentSelect},
  data() {
    return {
      EaTemplates: [],
      EaAssociationFunctions: [],
      filterOpen: false,
      hasAssociationFunctionsFieldValue: '',
      hasParticipationsFieldValue: '',
      needsTemplatesFieldValue: '',
      doesntHaveParticipationsFieldValue: '',

      fields: JSON.parse(JSON.stringify(filterDefaults))
    }
  },
  computed: {
    filters() {
      let inputFilters = this.fields
      let filters = {}
      if (inputFilters.agreedToPrivacy !== null) {
        filters.agreedToPrivacy = inputFilters.agreedToPrivacy
      }
      if (inputFilters.hasAnyFunction !== null) {
        filters.hasAnyFunction = inputFilters.hasAnyFunction
      }
      if (inputFilters.isMemberOfDepartments.length !== 0) {
        filters.isMemberOfDepartments = inputFilters.isMemberOfDepartments
      }
      if (inputFilters.hasFunctionInDepartment !== undefined) {
        filters.hasFunctionInDepartment = inputFilters.hasFunctionInDepartment;
      }
      if (inputFilters.hasAssociationFunctions.length > 0) {
        filters.hasFunctions = inputFilters.hasAssociationFunctions.map(x => x.id)
      }
      if (inputFilters.hasParticipations.length > 0) {
        filters.hasCompleted = inputFilters.hasParticipations.map(x => x.id)
      }
      if (inputFilters.doesntHaveParticipations.length > 0) {
        filters.doesntHaveCompleted = inputFilters.doesntHaveParticipations.map(x => x.id)
      }
      if (inputFilters.needsTemplates.length > 0) {
        filters.needsTemplates = inputFilters.needsTemplates.map(x => x.id)
        filters.needsTemplatesIsRequired = inputFilters.needsTemplatesIsRequired
      }
      if (inputFilters.firstName !== '') {
        filters.firstName = inputFilters.firstName
      }
      if (inputFilters.lastName !== '') {
        filters.lastName = inputFilters.lastName
      }
      if (inputFilters.mitgliedernummer !== '') {
        filters.mitgliedernummer = inputFilters.mitgliedernummer
      }
      if (inputFilters.hasCurrentParticipations !== null) {
        filters.hasCurrentParticipations = inputFilters.hasCurrentParticipations
      }
      return filters
    },
    filteredDoesntHaveParticipations() {
      return filterObjects(this.EaTemplates, 'name', this.doesntHaveParticipationsFieldValue).sort(function (a, b) {
        return a.name.localeCompare(b.name);
      })
    },
    filteredHasParticipations() {
      return filterObjects(this.EaTemplates, 'name', this.hasParticipationsFieldValue).sort(function (a, b) {
        return a.name.localeCompare(b.name);
      })
    },
    filteredHasFunctions() {
      return filterObjects(this.EaAssociationFunctions, 'name', this.hasAssociationFunctionsFieldValue);
    },
    filteredNeedsTemplates() {
      return filterObjects(this.EaTemplates, 'name', this.needsTemplatesFieldValue).sort(function (a, b) {
        return a.name.localeCompare(b.name);
      })
    }
  },
  watch: {
    filters() {
      this.$emit('input', this.filters)
      //this.$router.replace({name: this.$route.name, query: {...this.$route.query, ...this.filters}})
    }
  },
  mounted() {
    this.$emit('input', this.filters)
  },
  methods: {
    reset() {
      this.fields = JSON.parse(JSON.stringify(filterDefaults))
    }
  },
  apollo: {
    EaTemplates: {
      query: GET_TEMPLATES_QUERY,
      variables: {
        selectField: true
      }
    },
    EaAssociationFunctions: {
      query: GET_ASSOCIATION_FUNCTIONS_QUERY
    }
  }
}
</script>

<style>
.memberfilter .taginput .help.counter {
  display: none;
}
</style>
