const alphasort = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());

const memberUtils = {
  memberParticipationsCurrent: (member) => member.participations
    .filter(p => ((p.status === 'PARTICIPATING' || p.status === 'WAITING') && (p.seminar.status === 'PUBLIC' || p.seminar.status === 'INVITED')))
    .sort((a, b) => a.seminar.template.name === b.seminar.template.name ? a.date - b.date : alphasort(a.seminar.end, b.seminar.end)),
  memberParticipationsCompleted: (member) => member.participations
    .filter(p => (p.status === 'PARTICIPATING' && (p.seminar.status === 'COMPLETED' || p.seminar.status === 'CLEARED'))),
  memberCompleted: (member) => memberUtils.memberParticipationsCompleted(member).map(p => ({
    template_id: p.seminar.template.id,
    name: p.seminar.template.name,
    date: p.seminar.end
  })).concat(member.certificates.map(c => ({
    template_id: c.template.id,
    name: c.template.name,
    date: c.created_at
  }))).sort((a, b) => a.name === b.name ? a.date - b.date : alphasort(a.name, b.name)),
  memberRequirements: (member) => member.functions
    .reduce((accu, current) => accu.concat(current.requirements), [])
    .sort((a, b) => {
      if (a.isRequired & !b.isRequired) return -1;
      if (!a.isRequired & b.isRequired) return 1;
      return alphasort(a.template.name, b.template.name)
    })
    .filter((val, index, self) => self.findIndex(r => r.template.id === val.template.id) === index)
    .map(r => {
      let state = 1;
      if (memberUtils.memberParticipationsCurrent(member).findIndex(p => p.seminar.template.id === r.template.id) !== -1) state = 2;
      if (memberUtils.memberCompleted(member).findIndex(c => c.template_id === r.template.id) !== -1) state = 3;
      return {
        ...r,
        state
      }
    }),
  memberAmpel: (member) => {
    if (member.functions.length === 0) return 0
    let requirements = memberUtils.memberRequirements(member).filter(r => r.isRequired);
    if (requirements.filter(r => r.state === 1).length > 0) return 1;
    if (requirements.filter(r => r.state === 2).length > 0) return 2;
    return 3;
  },
  memberEducationStatus: (member) => ({
    ampel: memberUtils.memberAmpel(member),
    requirements: memberUtils.memberRequirements(member),
    completed: memberUtils.memberCompleted(member),
    currentParticipations: memberUtils.memberParticipationsCurrent(member)
  }),
  memberAddComputed: (member) => ({
    ...member,
    computed: memberUtils.memberEducationStatus(member)
  })
};


export default {
  member: memberUtils
}

export function filterObjects(objects, key, keyword) {
  return objects.filter(object => {
    return object[key].toLowerCase().includes(keyword.toLowerCase())
  })
}

import {Observable} from 'apollo-link';

export const promiseToObservable = promise =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err)
    );
    return subscriber; // this line can removed, as per next comment
  });

import _ from 'lodash'

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
}

export function different(object, base) {
  return Object.keys(difference(object, base)).length !== 0 || Object.keys(base).length !== Object.keys(object).length
}

export function filterObject(obj, key) {
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (i === key) {
      delete obj[key];
    } else if (typeof obj[i] == 'object') {
      filterObject(obj[i], key);
    }
  }
  return obj;
}