<template>
    <div class="">
        <h2>Meine Funktionen</h2>
        <b-table
            :data="EaMyFunctions"
            :default-sort="['department_name', 'ASC']"
            :loading="!!isLoading"
            :mobile-cards="false"
            bordered
            sortable
            striped
        >
          <b-table-column v-slot="props" field="function_name" label="Funktion" sortable>
            {{ props.row.function_name }}
            <!--{{props}}-->
          </b-table-column>
          <b-table-column v-slot="props" class="dw11" field="department_name" label="Verbandsstufe" sortable>
            {{ props.row.department_name }} <span
              v-if="props.row.level_name_short">{{ '(' + props.row.level_name_short + ')' }}</span>
            <!--{{props}}-->
          </b-table-column>
          <b-table-column v-if="$config.SHOW_ASSOCIATION_LEVELS" v-slot="props" class="dw3" field="level_name"
                          label="Ebene" numeric
                          sortable>
            <img v-if="props.row.level_name === 'Bezirksverband'" class="level_logo"
                 src="../assets/icons/bv.png"/>
            <img v-else-if="props.row.level_name  === 'Ortsverband'" class="level_logo"
                 src="../assets/icons/ov.png"/>
            <img v-else-if="props.row.level_name  === 'Kreisverband'" class="level_logo"
                 src="../assets/icons/kv.png"/>
            <img v-else-if="props.row.level_name  === 'Landesverband'" class="level_logo"
                 src="../assets/icons/lv.png"/>
          </b-table-column>
          <template slot="empty">
            <div v-if="EaMyFunctions.length === 0 && !isLoading" class="">
              <div class="has-text-centered empty">
                Sie haben aktuell keine Funktionen im VdK.
              </div>
            </div>
          </template>
        </b-table>
    </div>
</template>

<script>
import GET_MY_EDUCATION_QUERY from '../graphql/EaMyEducation.gql'

export default {
  name: "MyFunctions",
  data() {
    return {
      EaMyFunctions: [],
      isLoading: 0
    }
  },
  apollo: {
    EaMyFunctions: {
      query: GET_MY_EDUCATION_QUERY,
      loadingKey: 'isLoading'
    }
  }
}
</script>

<style scoped lang="scss">
.empty {
    padding: 1em;
}

.function {
    line-height: 2;
}

.level_logo {
    width: 28px;
    height: 28px;
    margin: -0.5em 0;
}

.function + .function {
    //border-top: 1px solid lightgrey;
}
</style>