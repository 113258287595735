<template>
  <b-modal :active="active" :has-modal-card="true" @close="$emit('close')" scroll="keep">
    <div class="modal-card is-relative">
      <b-loading :active="true" v-if="$apollo.loading"/>
      <template v-else>
        <header class="modal-card-head">
          <p class="modal-card-title">Anmeldung zu {{ EaSeminar.template.name }}</p>
        </header>
        <template v-if="!success">
          <div class="modal-card-body">
            <b-notification v-if="errorMessage" type="is-danger">{{ errorMessage }}</b-notification>
            <markdown-setting id="eaBeforeSignup" v-slot="{html}">
              <h2>Buchungshinweis</h2>
              <div class="notification content" v-html="html"></div>
            </markdown-setting>
            <template v-if="EaSeminar.description">
              <h2>Infos zu dieser Veranstaltung</h2>

              <div class="notification content is-warning m-t-md"
                   v-html="$options.filters.marked(EaSeminar.description)"></div>
            </template>
            <h2>Veranstaltungsbeschreibung</h2>
            <div class="content" v-html="$options.filters.marked(EaSeminar.template.description)"
                 v-if="EaSeminar.template.description"></div>
            <div v-if="$config.SHOW_PRICE && EaSeminar.price !== null" class="mb-5">
              <h2>Preis</h2>
              <seminar-price :price="EaSeminar.price" :small="true"/>
            </div>
            <seminar-appointments :appointments="EaSeminar.appointments"/>
          </div>
          <footer class="modal-card-foot">
            <div>
              <seminar-price v-if="$config.SHOW_PRICE" :price="EaSeminar.price">Preis:</seminar-price>
            </div>
            <button :class="{'is-loading' : isLoading}" @click="signUp" class="button is-success">Jetzt verbindlich
              anmelden!
            </button>
          </footer>
        </template>
        <template v-else>
          <div class="modal-card-body">
            <h2>Anmeldung erfolgreich</h2>
            <markdown-setting id="eaAfterSignup"/>
          </div>
          <footer class="modal-card-foot">
          </footer>
        </template>
      </template>
    </div>
  </b-modal>
</template>

<script>
import GET_SEMINAR_QUERY from '@/graphql/EaSeminar.gql'
import SIGN_UP_MUTATION from '@/graphql/EaSeminarSignup.gql'
import GET_MY_EDUCATION from '@/graphql/EaMyEducation.gql'
import {addParticipation} from '@/cacheUpdater'
import SeminarPrice from "@/components/SeminarPrice";
import MarkdownSetting from "@/components/MarkdownSetting";
import SeminarAppointments from "@/components/SeminarAppointments";

export default {
  name: "SeminarSignupModal",
  components: {SeminarAppointments, MarkdownSetting, SeminarPrice},
  props: ['seminarId', 'active'],
  data() {
    return {
      modalActive: false,
      EaSeminar: {},
      success: false,
      isLoading: false,
      errorMessage: ""
    }
  },
  computed: {},
  methods: {
    signUp() {
      this.errorMessage = ""
      this.isLoading = true
      this.$apollo.mutate({
        mutation: SIGN_UP_MUTATION,
        update: (cache, result) => addParticipation(cache, result),
        refetchQueries: [{query: GET_MY_EDUCATION}],
        variables: {
          id: this.seminarId
        },
      }).then(() => {
        this.isLoading = false
        this.success = true
      }).catch(() => {
        this.isLoading = false
        this.errorMessage = 'Entschuldigung, es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
      })
    }
  },
  apollo: {
    EaSeminar: {
      query: GET_SEMINAR_QUERY,
      variables() {
        return {
          id: this.seminarId
        }
      }
    }
  }
}
</script>

<style scoped>
.modal-card-foot {
  justify-content: space-between;
}

.modal-card-title {
  flex-shrink: 1;
}
</style>