<template>
  <div id="app">
    <div class="">
      <div class="navbar nav-container is-fixed-top">
        <div class="navbar-zone is-hidden-touch"/>
        <div class="navbar-center">
          <div class="navbar-brand">
            <router-link active-class="dummyclass" class="navbar-item" to="/">
              <img class="nav-logo" style="height: 56px;"
                   :src="$config.LOGO" alt="logo"/></router-link>

            <a @click="isExpanded = !isExpanded" aria-expanded="false"
               aria-label="menu" class="navbar-item has-text-black-ter is-hidden-desktop m-r-sm"
               style="margin-left:auto;">
              <b-icon icon="menu"></b-icon>
            </a>
          </div>
          <div :class="{'is-active' : isExpanded}" class="navbar-menu">
            <div class="navbar-start">
              <template v-if="$store.getters.loggedIn">
                <router-link class="navbar-item" to="/dashboard">Meine Seminare</router-link>
                <router-link class="navbar-item" to="/seminars">Alle Seminare</router-link>
                <router-link class="navbar-item" to="/analysis"
                             v-if="$config.SHOW_ANALYZE && $store.state.auth.user.isAnalyzer">Auswertung
                </router-link>
                <router-link class="navbar-item" to="/lectureships"
                             v-if="$store.state.auth.user.isLecturer">Seminarleitung
                </router-link>
                <router-link v-if="$config.SHOW_CONTACT_FORM" class="navbar-item" to="/contact">Kontakt
                </router-link>
              </template>
              <template v-else>
                <router-link class="navbar-item" exact to="/">Startseite</router-link>
                <router-link class="navbar-item"
                             to="/seminars">Alle Seminare
                </router-link>
                <router-link v-if="$config.SHOW_CONTACT_FORM" class="navbar-item" to="/contact">Kontakt</router-link>
              </template>
            </div>
            <div class="navbar-end">
              <div class="navbar-item is-hoverable has-dropdown" v-if="$store.getters.loggedIn">
                <div class="navbar-link">
                  <span>{{ $store.state.auth.user.fullName }}</span>
                </div>
                <div class="navbar-dropdown is-right">
                  <router-link class="navbar-item" to="/usersettings">
                    Benutzereinstellungen
                  </router-link>
                  <hr class="dropdown-divider is-hidden-touch">
                  <a @click="$store.dispatch('logout')" class="navbar-item">
                    Logout
                  </a>
                </div>
              </div>
              <template v-else>
                <router-link class="navbar-item" to="/login">
                  <button class="button is-primary is-hidden-touch">Login</button>
                  <div class="is-hidden-desktop">Login</div>
                </router-link>
                <router-link v-if="$config.SHOW_REGISTER" class="navbar-item" to="/register">
                  <button class="button is-primary is-hidden-touch">Registrieren</button>
                  <div class="is-hidden-desktop">Registrieren</div>
                </router-link>
                <div class="navbar-item is-hidden-desktop"/>
                <div v-if="$config.SECOND_EAAM_LINK" class="navbar-item is-hidden-desktop">
                  <a :href="$config.SECOND_EAAM_LINK" class="button is-second-eaam">{{
                      $config.SECOND_EAAM_LINK_TEXT
                    }}</a>
                </div>
              </template>

            </div>
          </div>
        </div>
        <div class="navbar-zone right is-hidden-touch">
          <div v-if="$config.SECOND_EAAM_LINK" class="navbar-item">
            <a :href="$config.SECOND_EAAM_LINK" class="button is-second-eaam">{{ $config.SECOND_EAAM_LINK_TEXT }}</a>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="backToEa">
        <a>Zurück zur Webseite</a>
    </div>-->
    <div class="contentarea m-t-lg">
      <div class="container">
        <router-view/>
        <div class="has-text-centered footer">
          <a href="//www.ehrenamtsakademie.de/impressum" target="_blank">Impressum</a> | <a
            href="//www.ehrenamtsakademie.de/datenschutz" target="_blank">Datenschutz</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: false
    }
  }
}</script>

<style lang="scss">
@import "./assets/style";
</style>
