<template>
  <div v-if="!!EaSetting && !!EaSetting.value">
    <slot :html="$options.filters.marked(EaSetting.value)">
      <div v-bind="$attrs" class="content" v-html="$options.filters.marked(EaSetting.value)"></div>
    </slot>
  </div>
</template>

<script>
import GET_SETTING_QUERY from '../graphql/EaSetting.gql'

export default {
  name: "MarkdownSetting",
  props: ['id'],
  data() {
    return {
      EaSetting: {},
      isLoading: 0
    }
  },
  apollo: {
    EaSetting: {
      query: GET_SETTING_QUERY,
      loadingKey: 'isLoading',
      variables() {
        return {
          id: this.id
        }
      }
    }
  }
}
</script>

<style scoped>

</style>