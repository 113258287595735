<template>
    <div class="columns">
        <div class="column is-6 is-offset-3 m-t-lg">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
  name: "AuthBase"
}
</script>

<style scoped>
p{
    line-height:1.5rem;
}
</style>