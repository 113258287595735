<template>
  <div v-if="!isLoading" class="seminarDetail">
    <div class="columns is-marginless is-gapless mb-2">
      <div class="column">
        <h2>{{ EaSeminar.template.name + " (" + EaSeminar.number + ")" }} </h2>
      </div>
      <div class="column is-narrow">
        <seminar-signup-button v-if="$config.SHOW_SEMINAR_REGISTER" :seminar="EaSeminar"/>
      </div>
    </div>
    <div class="notification content is-warning" v-html="$options.filters.marked(EaSeminar.description)"
         v-if="EaSeminar.description"></div>
    <div class="card mb-5">
      <div class="card-content">
        <template v-if="EaSeminar.template.description">
          <h2>Beschreibung</h2>
          <div class="content"
               v-html="$options.filters.marked(EaSeminar.template.description)"></div>
        </template>
        <seminar-appointments :appointments="EaSeminar.appointments"/>
      </div>
    </div>
    <button @click="$router.go(-1)" class="button m-t-lg">
      <b-icon icon="chevron-left"></b-icon>
      <span>Zurück</span></button>
  </div>
</template>

<script>
import GET_EA_SEMINAR_QUERY from '../graphql/EaSeminar.gql'
import Appointment from "../components/Appointment";
import SeminarSignupButton from "../components/SeminarSignupButton";
import SeminarAppointments from "@/components/SeminarAppointments";

export default {
  name: "SeminarDetail",
  components: {SeminarAppointments, SeminarSignupButton, Appointment},
  props: ['id'],
  data() {
    return {
      EaSeminar: {
        template: {
          name: 'Seminarname',
          description: "Blabliblub"
        },
        appointments: []
      },
      isLoading: 0
    }
  },
  apollo: {
    EaSeminar: {
      query: GET_EA_SEMINAR_QUERY,
      loadingKey: 'isLoading',
      variables() {
        return {
          id: this.id
        }
      }
    }
  }
}
</script>

<style scoped>
</style>